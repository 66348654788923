// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            false !default;
$enable-shadows:            false !default;
$enable-gradients:          false !default;
$enable-transitions:        true !default;
$enable-hover-media-query:  true !default;
$enable-grid-classes:       true !default;
$enable-print-styles:       true !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: Arial, sans-serif !default;
// $font-family-monospace:  Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
// $font-family-base:       $font-family-sans-serif !default;

// $font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
// $font-size-lg:   1.25rem !default;
// $font-size-sm:   .875rem !default;
// $font-size-xs:   .75rem !default;

// $font-weight-normal: normal !default;
// $font-weight-bold: bold !default;

// $font-weight-base: $font-weight-normal !default;
// $line-height-base: 1.5 !default;

// $font-size-h1: 2.5rem !default;
// $font-size-h2: 2rem !default;
// $font-size-h3: 1.75rem !default;
// $font-size-h4: 1.5rem !default;
// $font-size-h5: 1.25rem !default;
// $font-size-h6: 1rem !default;

// $headings-margin-bottom: ($spacer / 2) !default;
// $headings-font-family:   inherit !default;
$headings-font-weight:   800 !default;
// $headings-line-height:   1.1 !default;
$headings-color:         $dark-gray !default;

// $display1-size: 6rem !default;
// $display2-size: 5.5rem !default;
// $display3-size: 4.5rem !default;
// $display4-size: 3.5rem !default;

// $display1-weight:     300 !default;
// $display2-weight:     300 !default;
// $display3-weight:     300 !default;
// $display4-weight:     300 !default;
// $display-line-height: $headings-line-height !default;


//$grid-breakpoints: (
//        xs: 0,
//        sm: 576px,
//        md: 768px,
//        lg: 992px,
//        xl: 1200px
//) !default;
//@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
//@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1200px
) !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:               12 !default;
$grid-gutter-width-base:     3rem !default;
$grid-gutter-widths: (
        xs: $grid-gutter-width-base,
        sm: $grid-gutter-width-base,
        md: $grid-gutter-width-base,
        lg: $grid-gutter-width-base,
        xl: $grid-gutter-width-base
) !default;