.modal{
    &:not(.sender-newsletter) {
        .modal-dialog{
        }
        margin: 0;
        max-width: inherit;
        .modal-content{
            border: none;
            .modal-header{
                border-bottom: 1px solid #eee;
                padding: 1rem;
                position: relative;
                @include media-breakpoint-up(lg){
                    padding: 2.5rem;
                }
                .icon-metu{
                    position: absolute;
                    font-size: 2rem;
                    color: lighten($dark-gray, 60%);
                    @include media-breakpoint-up(lg){
                        font-size: 5rem;
                    }
                }
                .close{
                    position: fixed;
                    background-color: $orange;
                    color: $white;
                    opacity: 1;
                    padding: 0.2rem 0.5rem;
                    line-height: 1;
                    display: flex;
                    text-shadow: none;
                    right: 1rem;
                    top: 1rem;
                    transition: all 0.3s;
                    &:hover{
                        opacity: 0.8;
                    }
                    @include media-breakpoint-up(lg){
                        top: inherit;
                        right: 2rem;
                        padding: 0.8rem 1rem;
                        position: fixed;
                    }
                }
                .section-title{
                    display: flex;
                    align-self: center;
                    margin-bottom: 0;
                    max-width: 76%;
                    @include media-breakpoint-up(lg){
                        max-width: inherit;
                    }
                }
            }
            .modal-body{
                padding-top: 5rem;
                padding-bottom: 5rem;
            }
        }
    }
    &.sender-newsletter {
        .modal-dialog {
            max-width: fit-content;
        }
        .btn-container {
            display: flex;
            justify-content: flex-end;

            .close {
                float: none;
            }
        }
    }
}

.modal-backdrop{
    &.show{
        background-color: $white;
        opacity: 1;
    }
}