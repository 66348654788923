.phoneBookCards{
  color: $white;

  .btnPhoneBookSearch{
    cursor: pointer;
  }

  .card {
    border: none;
    background-color: $orange;
    flex-direction: row;

    .phoneBookImage {
      position: relative;
      max-width: 40%;
      height: 200px;
      width: 200px;
      background-color: #f7af0e;
      background-size: cover;
      background-position: center;
    }
    .phoneBookImagePlaceholder{
      position: relative;
      max-width: 40%;
      width: 200px;
      height: 200px;
      background-size: 75%;
      background-position: center;
      background-repeat: no-repeat;
    }

    .pictureBorder {
      border: 2px solid white;
      position: absolute;
      height: 90%;
      width: 90%;
      background: transparent;
      top: 5%;
      left: 5%;
    }

    .card-block {
      max-width: 60%;
      .card-block-name {
        font-size: 1rem;
        @include media-breakpoint-down(xs) {
          font-size: 0.75rem;
        }
      }
      .card-block-text {
        font-size: 0.75rem;
        @include media-breakpoint-down(xs) {
          font-size: 0.6rem;
        }
      }
      .phoneBookCardBackSide {
        display: none;
      }
      .flipButton{
        width: 25%;
        cursor: pointer;
        margin: 0;
        position: absolute;
        bottom: 0.5rem;
        right: 1rem;
        background-color: white;
        color: $orange;
        @include media-breakpoint-down(lg){
          width: 35%;
        }
        @include media-breakpoint-down(xs){
          width: calc(60% - 2rem);
        }
      }
      .flipButton.flipButtonLeft{
        display: none;
      }
    }
  }
}

.dropdown-item input[type="checkbox"]{
  display: none;
}

.search-checked{
  background-color: $orange;
  color: white;
}
