.list-of-news, .list-of-events {
  .list-news-item, .list-event-item {
    position: relative;
    height: 100%;
    display: block;
    border: 1px solid $lighter-gray;
    transition: all 0.3s;
    @include media-breakpoint-up(md) {
      display: -webkit-flex;
      display: flex;
      &:hover {
        text-decoration: none;
        background-color: $orange;
        .item-title {
          color: $white;
        }
        .item-content p, .item-content .item-meta {
          color: $white;
        }
      }
    }
    .item-img {
      img {
        width: 100%;
        @include media-breakpoint-up(md) {
          width: auto;
        }
      }
    }
    .item-categories {
      white-space: nowrap;
      position: absolute;
      display: block;
      right: 0;
      top: 0;
      line-height: 0;
      .category-badge {
        font-size: 0.5rem;
        letter-spacing: 0.03rem;
        display: inline-block;
        line-height: 1;
        border: none;
        padding: 0.3rem 0.5rem;
        text-transform: uppercase;
        color: $white;
        &.orange {
          background-color: $orange;
        }
        &.real_orange {
          background-color: $real_orange;
        }
        &.green {
          background-color: $green;
        }
        &.turquoise {
          background-color: $turquoise;
        }
        &.purple {
          background-color: $purple;
        }
        &.dark-blue {
          background-color: $dark-blue;
        }
      }
    }
    .item-content {
      padding: 1em;
      @include media-breakpoint-up(md) {
        padding: 1.5em;
      }
      p {
        font-size: 0.85em;
        color: lighten($black, 50%);
      }
      .item-title {
        font-weight: 600;
        font-size: 1.2em;
      }
      .item-meta {
        font-size: 0.7em;
        text-transform: uppercase;
        color: lighten($black, 60%);
        font-weight: 600;
        padding: 1em;
        [class^="icon-"] {
          margin-right: 0.5em;
        }
        @include media-breakpoint-up(md) {
          position: absolute;
          bottom: 0;
          top: inherit;
          right: 0;
        }
      }
    }
  }
  .list-event-item {
    .item-content {
      .item-meta {
        padding-left: 0;
        position: relative;
        @include media-breakpoint-up(md) {
          //position: absolute;

          //bottom: 0;
          //top: inherit;
          //right: 0;
        }
      }
    }
    .date-label{
      background-color: $orange;
      padding: 0.8em 1em;
      text-align: center;
      line-height: 1.2;
      font-size: 0.65rem;
      color: $white;
      position: absolute;
      top: 0;
      left: 1rem;
      text-transform: uppercase;
    }
  }
}

.list-of-events {
  .tiles-overflow {
    .tiles-strip {
      grid-auto-flow: unset;
      grid-template-columns: 1fr;

      //text-xl-left col-12 col-md-6 col-xl-3

      @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, 1fr);
      }
      
      @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}

#single_workers{
  .text-columns-content{
    ul{
      color: lighten($gray, 20%);
      font-size: 0.7rem;
      @include media-breakpoint-up(lg) {
        font-size: 0.95rem;
      }
    }
  }
}

#jobs{
  .text-columns-content{
    p{
      -webkit-column-count: 1; /* Chrome, Safari, Opera */
      -moz-column-count: 1; /* Firefox */
      column-count: 1;
    }
    ul{
      color: lighten($gray, 20%);
      font-size: 0.7rem;
      @include media-breakpoint-up(lg) {
        font-size: 0.95rem;
      }
    }
  }
}

