/*.ticker-headline {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 15px 0;
  margin: 0;
  font-size: 18px;
}

.carousel.vertical .carousel-inner {
  height: 100%;
  width: auto;
  & > .item {
    width: auto;
    padding-right: 50px;
    transition: 0.6s ease-in-out top;
    @media all and (transform-3d), (-webkit-transform-3d) {
      transition: 0.6s ease-in-out;
      &.next,
      &.active.right {
        transform: translate3d(0, 100%, 0);
        top: 0;
      }
      &.prev,
      &.active.left {
        transform: translate3d(0, -100%, 0);
        top: 0;
      }
      &.next.left,
      &.prev.right,
      &.active {
        transform: translate3d(0, 0, 0);
        top: 0;
      }
    }
  }
  > .active,
  > .next,
  > .prev {
    display: block;
  }
  > .active {
    top: 0;
  }
  > .next,
  > .prev {
    position: absolute;
    top: 0;
    width: 100%;
  }
  > .next {
    top: 100%;
  }
  > .prev {
    top: -100%;
  }
  > .next.left,
  > .prev.right {
    top: 0;
  }
  > .active.left {
    top: -100%;
  }
  > .active.right {
    top: 100%;
  }
}

.carousel.vertical {
  .carousel-control {
    left: auto;
    width: 50px;
    &.up {
      top: 0;
      right: 0;
      bottom: 50%;
    }
    &.down {
      top: 50%;
      right: 0;
      bottom: 0;
    }
    // Toggles
    .icon-prev,
    .icon-next,
    .glyphicon-chevron-up,
    .glyphicon-chevron-down {
      position: absolute;
      top: 50%;
      z-index: 5;
      display: inline-block;
    }
    .icon-prev,
    .glyphicon-chevron-up {
      left: 50%;
      margin-left: -10px;
      top: 50%;
      margin-top: -10px;
    }
    .icon-next,
    .glyphicon-chevron-down {
      left: 50%;
      margin-left: -10px;
      top: 50%;
      margin-top: -10px;
    }
    .icon-up,
    .icon-down {
      width: 20px;
      height: 20px;
      //margin-top: -10px;
      line-height: 1;
      font-family: serif;
    }
    .icon-prev {
      &:before {
        content: '\2039';
        // SINGLE LEFT-POINTING ANGLE QUOTATION MARK (U+2039)

      }
    }
    .icon-next {
      &:before {
        content: '\203a';
        // SINGLE RIGHT-POINTING ANGLE QUOTATION MARK (U+203A)

      }
    }
  }
}*/