@font-face {
  font-family: 'metu';
  src: url('../../fonts/metu/metu.eot?71192909');
  src: url('../../fonts/metu/metu.eot?71192909#iefix') format('embedded-opentype'),
  url('../../fonts/metu/metu.woff2?71192909') format('woff2'),
  url('../../fonts/metu/metu.woff?71192909') format('woff'),
  url('../../fonts/metu/metu.ttf?71192909') format('truetype'),
  url('../../fonts/metu/metu.svg?71192909#metu') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "metu";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left:before { content: '\e800'; }
.icon-arrow-right:before { content: '\e801'; }
.icon-arrow-up:before { content: '\e802'; }
.icon-barrier-empty:before { content: '\e803'; }
.icon-barrier-filled:before { content: '\e804'; }
.icon-chain:before { content: '\e805'; }
.icon-chevron-down:before { content: '\e806'; }
.icon-chevron-left:before { content: '\e807'; }
.icon-chevron-right:before { content: '\e808'; }
.icon-chevron-up:before { content: '\e809'; }
.icon-clock:before { content: '\e80a'; }
.icon-close:before { content: '\e80b'; }
.icon-down-arrow-circle:before { content: '\e80c'; }
.icon-envelope:before { content: '\e80d'; }
.icon-event:before { content: '\e80e'; }
.icon-facebook-circled:before { content: '\e80f'; }
.icon-globe:before { content: '\e810'; }
.icon-hamburger:before { content: '\e811'; }
.icon-instagram-circled:before { content: '\e812'; }
.icon-linkedin-circled:before { content: '\e813'; }
.icon-marker:before { content: '\e814'; }
.icon-megaphone:before { content: '\e815'; }
.icon-metu:before { content: '\e816'; }
.icon-pantheon:before { content: '\e817'; }
.icon-pencil:before { content: '\e818'; }
.icon-phone:before { content: '\e819'; }
.icon-play-circled:before { content: '\e81a'; }
.icon-search:before { content: '\e81b'; }
.icon-shooting:before { content: '\e81c'; }
.icon-small-chevron-down:before { content: '\e81d'; }
.icon-small-chevron-left:before { content: '\e81e'; }
.icon-small-chevron-right:before { content: '\e81f'; }
.icon-small-chevron-up:before { content: '\e820'; }
.icon-twitter-circled:before { content: '\e821'; }
.icon-youtube-circled:before { content: '\e822'; }
.icon-arrow-down:before { content: '\e823'; }

.bordered-icon{
    padding: 0.5em;
    width: 2.5em;
    line-height: 1.2;
    text-align: center;
    height: 2.5em;
    margin: 0 auto;
    border: 3px solid;
    margin-bottom: 1em;
    &.border-orange{
        border-color: $orange;
    }
    &.border-green{
      border-color: $green;
    }
    &.border-turquoise{
      border-color: $turquoise;
    }
    &.border-purple{
      border-color: $purple;
    }
} 