.footer-content {
    font-size: 0.8rem;
    a {
        color: #a0a0a0;
    }
    .btn {
        &.btn-orange {
            background-color: $orange;
            color: $white;
            text-transform: uppercase;
            font-size: 0.6rem;
            font-weight: bold;
            display: block;
            padding: 1.2em 2em;
            @include media-breakpoint-up(md) {}
            &:hover {
                background-color: darken($orange, 5%);
            }
            &:after {
                content: '\e81f';
                font-weight: bold;
                font-family: 'metu';
            }
        }
    }
    .brand {
        margin: 0 1.5rem;
        padding: 1rem;
        background-color: $orange;
        text-align: center;
        display: block;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        padding-top: 1rem;
        margin-bottom: 2rem;
        padding-bottom: 1rem;
        img {
            height: 80px;
            @include media-breakpoint-up(lg) {
                height: 120px;
            }
        }
        @include media-breakpoint-up(lg) {
            display: block;
            height: 160px;
            width: 160px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1rem;
        }
    }
    .footer-title {
        font-weight: 800;
        font-size: 0.9rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid $lighter-gray;
        display: block;
        position: relative;
        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 1.5rem;
            height: 2px;
            background-color: $orange-dark;
        }
    }
    .footer-bottom-bar {
        margin-top: 2rem;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        color: $white;
        background-color: $dark-gray;
        font-size: 0.7rem;
        letter-spacing: 1px;
        .footer-social-links {
            padding-right: 2rem;
            a {
                display: inline-block;
                vertical-align: middle;
                color: $white;
                transition: all 0.2s;
                [class^="icon-"] {
                    font-size: 1.6rem;
                    &:before {
                        margin: 0;
                    }
                }
                &:hover {
                    color: $orange;
                }
            }
        }
        .bring-me-top {
            background-color: transparent;
            color: $orange;
            border: 0;
            width: 3rem;
            height: 3rem;
            transition: all 0.3s;
            margin-top: -2rem;
            margin-left: auto;
            margin-right: auto;
            display: table;
            position: relative;
            // @include media-breakpoint-up(lg) {
            //     position: absolute;
            //     margin-top: -1.5rem;
            //     right: 60px;
            //     top: -0.7vh;
            // }
            @include media-breakpoint-up(xl) {
                margin-top: -1.5rem;
                right: -60px;
                top: -0.7vh;
            }
            span {
                display: block;
                &:before {
                    margin-top: 1.5rem;
                    font-size: 1.1rem;
                    transition: all 0.3s;
                    z-index: 1;
                }
                &:after {
                    z-index: -1;
                    content: '';
                    display: block;
                    width: 100%;
                    height: 1.5rem;
                    border-left: 1.5rem solid transparent;
                    border-right: 1.5rem solid transparent;
                    border-bottom: 1.5rem solid $dark-gray;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                }
            }
            &:hover {
                span {
                    &:before {
                        transform: translateY(-0.3rem);
                    }
                }
            }
        }
    }
}
