#single_workers {  
  .metuLogoContainer {
    display: flex;
    align-items: center;

    img {
      width: 100%;
    }
  }

  .singleworkerProfileImg {
    img {
      width: 100%;
      border: 2px solid white;
    }
  }
  .singleworkerTitle {
    position: relative;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    color: white;
    h5 {
      font-size: 1.25em;
      font-weight: bold;
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
      color: white;
      @media (max-width: 1199px) {
        font-size: 1em;
        @media print{
          font-size: 1rem;
        }
      }
      @media (max-width: 767px) {
        font-size: 0.75em;
        @media print{
          font-size: 0.75em;
        }
      }
      @media (max-width: 575px) {
        font-size: 0.7em;
        @media print{
          font-size: 0.7em;
        }
      }
    }

    .hrContainer {
      hr {
        width: 20%;
        margin: 0;
        border-top: 2px solid white;
      }
    }

    .universityStatus {
      font-size: 0.875rem;
      @include media-breakpoint-down(lg) {
        font-size: 0.75rem;
        @media print{
          font-size: 0.75rem;
        }
      }
      @include media-breakpoint-down(sm) {
        font-size: 0.625rem;
        @media print{
          font-size: 0.625rem;
        }
      }
      @include media-breakpoint-down(xs) {
        font-size: 0.55rem;
        @media print{
          font-size: 0.55rem;
        }
      }
    }

    .singleworkerLanguages {
      display: none;
      position: absolute;
      bottom: 0;
      font-size: 0.875rem;
      @include media-breakpoint-down(lg) {
        font-size: 0.75rem;
        @media print{
          font-size: 0.75rem;
        }
      }
      @include media-breakpoint-down(sm) {
        font-size: 0.625rem;
        @media print{
          font-size: 0.625rem;
        }
      }
      @include media-breakpoint-down(xs) {
        font-size: 0.55rem;
        @media print{
          font-size: 0.55rem;
        }
      }
      @media print {
        display: block;
      }
    }
  }

  .dataSheetContainer {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    background: $orange;
  }
  .dataSheetWorkerDataContainer .row:first-child{
    padding-top: 1rem;
  }
  h6 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    color: #4d4d4d;
    &:before {
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
      border-color: $orange;
      display: inline-flex;
    }
  }

  .text-columns-content {
    p {
      column-count: 1;
      padding-left: 1rem;
    }
    p, li {
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
      color: lighten($gray, 20%);
    }
  }
}

@media print{
  #single_workers {
    zoom: 95%;
  }
  header, footer, #phantom-nav, .printButton, .cookie-bar, .sf-toolbar{
    display: none!important;
  }
}

