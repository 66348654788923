.media {
  &.green-media-button {
    background-color: $dark-green;
    color: $white;
    text-decoration: none;
    transition: all 0.3s;
    &:hover {
      background-color: lighten($dark-green, 5%);
    }
    .media-heading {
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.05rem;
      font-size: 0.75rem;
      margin-left: 1rem;
      margin-bottom: 0;
      color: $white;
    }
    .img-wrap {
      margin-top: -1rem;
      margin-bottom: -1rem;
      img {
        max-height: 70px;
      }
    }
  }

  &.colorable-media-button {
    color: $white;
    text-decoration: none;
    transition: all 0.3s;

    .media-heading {
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.05rem;
      font-size: 0.75rem;
      margin-left: 1rem;
      margin-bottom: 0;
      color: $white;
    }
    .img-wrap {
      margin-top: -1rem;
      margin-bottom: -1rem;
      img {
        max-height: 70px;
      }
    }
  }

  .icon-wrap {
    padding: 1rem 1.2rem;
    .icon-small-chevron {
      &:before {
        content: '\e81d';
        transform: rotate(180deg);
        transition: all 0.3s;
      }
    }
  }
}

.btn-lg {
  &.btn-inverse {
    font-size: 1.3rem;
    color: $white;
    border-color: $white;
    text-transform: uppercase;
    font-weight: 900;
    border-width: 3px;
    &:hover {
      background-color: rgba($white, 0.1);
    }
  }
}

.caret-btn {
  padding: 0.6rem 0.8rem;
}

.nav {
  &.metu-tabs {
    @include media-breakpoint-up(lg) {
      display: flex;
      padding-bottom: 1rem;
    }

    &.no-bullet-orange {
      .nav-item {
        display: block;
        .nav-link {
          font-size: 0.8rem;
          z-index: 100;
          border: 3px solid $orange;
          border-right: none;
          color: $orange;
          background-color: $white;
          transition: all 0.3s;
          @include media-breakpoint-down(md){
            font-size: 0.5rem;
          }
          @include media-breakpoint-down(sm){
            padding: 0.5rem;
          }
          &:hover {
            background-color: rgba($black, 0.1);
          }
          &:before {
            display: none;
          }
          &:after {
            display: none;
            top: 100%;
            margin-top: 3px;
            border-top: 1.2em solid $orange;
            border-left: 1.2em solid transparent;
            border-right: 1.2em solid transparent;
          }
          &.active {
            background-color: $orange;
            color: $white;
            &:after {
              display: block;
            }
          }
        }
        &:last-child {
          .nav-link {
            border-right: 3px solid $orange;
          }
        }
      }
    }

    .nav-item {
      width: 100%;
      display: table-cell;
      .nav-link {
        font-size: 0.6rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-align: center;
        background-color: lighten($lighter-gray, 5%);
        color: $dark-gray;
        padding: 1em;
        display: block;
        @include media-breakpoint-up(lg) {
          position: relative;
          border: 3px solid $white;
          font-size: 0.7rem;
          font-weight: 600;
          color: $white;
          background-color: transparent;
          &:before {
            content: '';
            display: inline-block;
            font-size: 0;
            width: 1.1rem;
            height: 1.1rem;
            margin: auto 0.5rem auto auto;
            vertical-align: middle;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: icon(radioOffWhite, transparent);
          }
          &:after {
            content: '';
            display: block;
            border-left: 0.5rem solid transparent;
            border-right: 0.5rem solid transparent;
            border-top: 0.5rem solid $white;
            width: 0;
            position: absolute;
            left: 50%;
            margin-left: -0.5rem;
            bottom: -0.5rem;
          }
        }
        &.active {
          color: $orange;
          background-color: $white;
          @include media-breakpoint-up(lg) {
            &:before {
              background-image: icon(radioOn, transparent);
            }
          }
        }
      }
    }
  }
}

.btn {
  &.btn-blue {
    background-color: $turquoise;
    color: $white;
    padding: 1rem;
    border-color: $turquoise;
    font-size: 0.6rem;
    letter-spacing: 0.05rem;
    font-weight: bold;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
      font-size: 0.8rem;
      &:hover {
        background-color: lighten($turquoise, 5%);
      }
    }
  }
}

.pagination {
  li {
    padding: 0.1em;
    display: inline-block;
    float: left;
    a {
      display: table-cell;
      vertical-align: middle;
      width: 2.5rem;
      text-align: center;
      height: 2.5rem;
      cursor: pointer;
      border: 1px solid rgba($black, 0.05);
      color: $dark-gray;
      font-size: 0.8rem;
      &:hover {
        background-color: lighten($lighter-gray, 6%);
      }
    }
  }
  .navigator_first {
    a{
      &:before{
        content: '\e807 \e807';
        font-family: 'metu';
        letter-spacing: -0.3em;
        margin-left: -0.3em;
      }
    }
  }
  .navigator_prev {
    a{
      &:before{
        content: '\e807';
        font-family: 'metu';
      }
    }
  }
  .prev_placeholder, .next_placeholder {
    a {
      cursor: default;
      border: none;
      &:hover {
        background-color: transparent;
      }
    }
  }
  .navigator_next {
    a{
      &:before{
        content: '\e808';
        font-family: 'metu';
      }
    }
  }
  .navigator_last {
    a{
      &:before{
        content: '\e808 \e808';
        font-family: 'metu';
        letter-spacing: -0.3em;
        margin-left: -0.3em;
      }
    }
  }
  .navigation_item {
    a {
      &:hover {
        background-color: lighten($lighter-gray, 6%);
      }
    }
    &.active {
      a {
        background-color: $orange;
        color: $white;
      }
    }
  }
}

.newsletter-subscribe{
  @include media-breakpoint-down(lg){
    padding: 1rem;
    .btn.btn-outline-secondary.btn-lg.btn-inverse{
      font-size: 1rem;
      }
  }
}