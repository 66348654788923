.tiles-wrap {
    &.gallery-tiles {
        .tiles-control{
            [class^='icon-']{
                box-shadow: none;
                background-color: transparent;
                color: $dark-gray;
                &:before{
                    font-weight: 900;
                }
            }
            &.right{
                right: -3rem;
            }
            &.left{
                left: -3rem;
            }
        }
        .tiles-overflow {
            .tiles-strip {
                width: max-content;

                .gallery-tile-group:first-child {
                    border-left: 1px solid #e5e4e4;
                }
                .gallery-tile-group {
                    border-right: 1px solid #e5e4e4;
                    border-top: 1px solid #e5e4e4;
                    border-bottom: 1px solid #e5e4e4;
                    a:first-child {
                        border-bottom: 1px solid #e5e4e4;
                    }
                }
                .tile-group {
                    .tile {
                        &.gallery-tile {
                            padding: 0;
                            display: inline-block;
                            @include media-breakpoint-up(lg) {
                                display: block;
                                &:hover {
                                    .social-tile-hover {
                                    }
                                }
                            }
                            .bg-image-tile {
                                height: 100%;
                                background-size: cover;
                                background-position: center;
                                position: relative;
                            }
                            &:after {
                                content: '';
                                opacity: 0;
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background-color: rgba($white, 0.3);
                                transition: all 0.3s;
                            }
                            &:hover {
                                &:after {
                                    opacity: 1;
                                }
                            }
                            .galleryNameForrasContainer {
                                position: absolute;
                                bottom: 0;
                                white-space: normal;
                                color: #fff;
                                background-color: rgba(0, 0, 0, 0.6);
                                display: flex;
                                height: 40%;
                                align-items: center;
                                justify-content: center;
                                @include media-breakpoint-down(md) {
                                    font-size: 0.8rem;
                                }
                            }
                        }
                        &.singleGalleryTile{
                            height: 2000px;
                            .galleryNameForrasContainer{
                                position:absolute;
                                bottom: 0;
                                white-space: normal;
                                color: #fff;
                                background-color: rgba(0,0,0,0.6);
                                display: none;
                                height: 100%;
                                @include media-breakpoint-down(md){
                                    font-size: 0.8rem;
                                }
                            }
                            &:after{
                                display: none;
                            }
                            &:hover{
                                .galleryNameForrasContainer{
                                    display: flex;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}