.accordion-item {
    border: none;
    .media {
        background-color: $dark-blue;
        color: $white;
        text-decoration: none;
        transition: all 0.3s;
        &:hover {
            background-color: lighten($dark-blue, 5%);
        }
        .media-heading {
            text-transform: uppercase;
            font-weight: normal;
            font-size: 0.6rem;
            margin-bottom: 0;
            color: $white;
            margin-left: 0.5rem;
            letter-spacing: 0.05rem;
            @include media-breakpoint-up(lg) {
                letter-spacing: 0.05rem;
                font-size: 0.75rem;
                margin-left: 1rem;
            }
        }
        .icon-wrap {
            padding: 0.2rem 0.5rem;
            background-color: lighten($dark-blue, 10%);
            @include media-breakpoint-up(lg) {
                padding: 1rem 1.2rem;
            }
            .icon-small-chevron {
                &:before {
                    content: '\e81d';
                    transform: rotate(180deg);
                    transition: all 0.3s;
                }
            }
        }
        &.collapsed {
            .icon-wrap {
                .icon-small-chevron {
                    &:before {
                        transform: rotate(0);
                    }
                }
            }
        }
    }
    .card-block {
        border-left: 1px solid $lighter-gray;
        border-right: 1px solid $lighter-gray;
        border-bottom: 1px solid $lighter-gray;
        font-size: 0.7rem;
        @include media-breakpoint-up(lg) {
            font-size: 0.95rem;
        }
    }
}