.card {
  &.card-quick {
    width: 25%;
    border: none;
    text-decoration: none;
    color: $gray;
    transition: all 0.3s;
    display: inline-block;
    @include media-breakpoint-up(md) {
      &:hover {
        text-decoration: none;
        opacity: 0.6;
      }
    }
    @include media-breakpoint-up(lg) {
      display: block;
      border-left: none;
      border-bottom: none;
      border-top: none;
      border-right: 1px solid $lighter-gray;
    }
    &:last-child {
      @include media-breakpoint-up(lg) {
        border-right: none;
      }
    }
    @each $color in ('turquoise': $turquoise, 'orange': $orange, 'purple': $purple, 'green': $green) {
      $key: nth($color, 1);
      $value: nth($color, 2);
      &.border-#{$key} {
        background-color: $value;
        @include media-breakpoint-up(lg) {
          background-color: transparent;
        }
        .card-block {
          .icon-wrap {
            border-color: inherit;
            @include media-breakpoint-up(lg) {
              border-color: $value;
            }
          }
        }
        .card-title {
          background-color: $value;
          color: $white;
          @include media-breakpoint-up(lg) {
            background-color: $white;
            color: inherit;
          }
        }
      }
    }
    .card-block {
      background-color: transparent;
      padding: 0;
      @include media-breakpoint-up(lg) {
        padding: 1rem;
      }
      .icon-wrap {
        color: $white;
        font-size: 1.4rem;
        padding: 1rem 0 0.8rem 0;
        @include media-breakpoint-up(lg) {
          border: 3px solid;
          background-color: transparent;
          display: inline-block;
          font-size: 1rem;
          border-color: inherit;
          padding: 0.3rem 0.6rem;
          color: $dark-gray;
          margin-bottom: 1.5rem;
        }
      }
      .card-title {
        font-size: 0.5rem;
        text-transform: uppercase;
        padding-bottom: 1rem;
        margin-bottom: 0;
        @include media-breakpoint-up(md) {
          font-size: 0.75rem;
          letter-spacing: 1px;
          font-weight: 800;
          padding: 0;
        }
        @include media-breakpoint-up(lg) {
          font-size: 0.9rem;
          font-weight: 600;
          margin-bottom: inherit;
          padding-bottom: inherit;
        }
      }
      .card-text {
        display: none;
        @include media-breakpoint-up(md) {
          font-size: 0.7rem;
          padding: 1rem;
          display: inherit;
          color: $white;
        }
        @include media-breakpoint-up(lg) {
          font-size: 0.75rem;
          padding: 0;
          color: inherit;
        }
      }
    }
    .card-footer {
      border: none;
      display: none;
      background-color: transparent;
      @include media-breakpoint-up(md) {
        display: block;
        &:before {
          content: '\e808';
          font-size: 0.7rem;
          color: $white;
          font-family: 'metu';
          font-weight: bold;
        }
      }
      @include media-breakpoint-up(lg) {
        display: inherit;
        &:before {
          display: none;
        }
      }
      .card-link {
        display: none;
        @include media-breakpoint-up(lg) {
          display: inline-block;
        }
        @include media-breakpoint-up(md) {
          text-transform: uppercase;
          font-size: 0.8rem;
          font-weight: 600;
          letter-spacing: 0.05rem;
        }
      }
    }
  }
}