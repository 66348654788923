.person-group {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    @include media-breakpoint-up(md) {
        margin-left: 0;
        margin-right: 0;
    }
    .person-row {
        margin-left: -0.25rem;
        margin-right: -0.25rem;
    }
    .card-deck {
        white-space: nowrap;
        overflow-x: auto;
        @include media-breakpoint-up(md) {
            white-space: inherit;
            overflow-x: inherit;
        }
        .card {
            transition: all 0.3s;
            max-width: 200px;
            display: inline-flex;
            margin: 0;
            @include media-breakpoint-up(md) {
                max-width: inherit;
                display: inherit;
            }
            &:not(:first-child) {
                @include media-breakpoint-up(md) {
                    margin-left: 0.3rem;
                }
            }
            &:not(:last-child) {
                @include media-breakpoint-up(md) {
                    margin-right: 0.3rem;
                }
            }
            .link-label {
                font-weight: 600;
                text-transform: uppercase;
                font-size: 0.9rem;
                white-space: normal;
            }
            &:hover {
                opacity: 0.8;
            }
        }
    }
    .person-item {
        background-color: $white;
        color: $dark-gray;
        transition: all 0.3s;
        .person-item-img {
            display: block;
            @include clearfix();
            .img-fluid{
                height: 9.5rem;
                width: 100%;
                padding: 5% 25%;
                @include media-breakpoint-down(lg) {
                    height: 7rem;
                }
                @include media-breakpoint-down(md) {
                    height: 5rem;
                }
                @include media-breakpoint-down(sm) {
                    height: 6rem;
                }
            }
        }
        .partner-item-img {
            display: block;
            @include clearfix();
            .img-fluid{
                margin: 0 auto;
                max-height: 9.5rem;
                width: auto;
                padding: 5% 10%;
                @include media-breakpoint-down(lg) {
                    max-height: 7rem;
                }
                @include media-breakpoint-down(md) {
                    max-height: 5rem;
                }
                @include media-breakpoint-down(sm) {
                    max-height: 6rem;
                }
            }
        }
        .person-item-footer {
            min-height: 1px;
            text-align: center;
            display: table;
            width: 100%;
            .footer-label {
                display: table-cell;
                font-weight: bold;
                text-transform: uppercase;
                vertical-align: middle;
                height: 3rem;
                letter-spacing: 0.05rem;
                font-size: 0.6rem;
                @include media-breakpoint-up(md) {
                    height: 4rem;
                    letter-spacing: 0.02rem;
                    font-size: 0.9rem;
                }
            }

            button {
                margin-bottom: 1rem;
            }
        }
        &:hover {
            text-decoration: none;
        }
        .person-name {
            margin-top: 1rem;
            font-weight: 600;
            display: block;
            text-transform: uppercase;
        }
        .person-specs {
            display: block;
            max-width: 100%;
            text-align: center;
            white-space: normal;
            padding-left: 1rem;
            padding-right: 1rem;
            padding-bottom: 1rem;
            font-size: 0.7rem;
            color: lighten($gray, 20%);
        }
    }
}


.partners-group, .collegues-group{
    .person-item {
        cursor: pointer;

        button {
            cursor: inherit;
        }

        .person-item-img {
            padding: 0;
            .img-fluid {
                padding: 5% 20%;
            }
        }
        .person-name{
            margin-top: 0;
            padding: 0.5rem 1rem;
            @include media-breakpoint-down(lg) {
                font-size: 0.75rem;
            }
            @include media-breakpoint-down(md) {
                font-size: 0.6rem;
                padding-left: 0;
                padding-right: 0;
            }
            @include media-breakpoint-down(sm) {
                font-size: 0.5rem;
            }
            @include media-breakpoint-down(xs) {
                font-size: 0.4rem;
            }
        }
        .person-specs{
            @include media-breakpoint-down(md) {
                padding-left: 0;
                padding-right: 0;
            }
            p{
                margin-bottom: 0;
            }
        }
    }
    .card-deck{
        .card{
            max-width: 33%;
            @include media-breakpoint-down(xs) {
                max-width: none;
                width: 50%;
            }

        }
    }
    @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-right: 0;
    }
}