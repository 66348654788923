.library-search-group {
    border: none;
    .input-group-addon {
        background-color: $white;
        border: none;
        padding-left: 1.5rem;
        [class^="icon-"] {
            font-size: 1.5rem;
            &:before {
                font-weight: bold;
            }
        }
    }
    .form-control {
        border: none;
        padding: 1.3rem;
    }
    .input-group-btn {
        .btn {
            &.btn-secondary {
                font-weight: 900;
                border: none;
                color: $dark-blue;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-size: 0.75rem;
                padding-right: 1rem;
                padding-left: 1.5rem;
            }
        }
    }
}