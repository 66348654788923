#news-tiles {
  border-left: 1px solid $lighter-gray;
  border-right: 1px solid $lighter-gray;
}

.tiles-wrap {
  @include media-breakpoint-up(lg) {
    width: inherit;
    margin-bottom: 5rem;
  }
  position: relative;
  .tiles-overflow {
    //background-color: $lighter-gray;
    overflow: hidden;
    .tiles-strip {
      transition: all 0.2s;
      position: relative;
      white-space: nowrap;
      display: grid;
      grid-auto-flow: column;

      .tile-group {
        cursor: pointer;
        box-sizing: border-box;
        display: inline-block;
        vertical-align: top;
        height: 100%;
        &.communication {
        }
        &.business {
        }
        &.art {
        }
        &.tourism {
        }

        .tile {
          line-height: $line-height-base;
          background-color: white;
        }
        .card-text {
          word-wrap: break-word;
        }
        &.news-group {
          position: relative;
          display: inline-block;
          transition: all 0.3s;
          .tile-hover {
            transform: scale(0.8);
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s;
            background: $orange;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 1rem;
            color: $white;
            overflow: hidden;
            .categories {
              position: inherit;
              display: block;
              .category-badge {
                border-left: 1px solid $white;
                padding: 0 0.3rem;
                font-size: 0.5rem;
                text-transform: uppercase;
                &:last-child {
                  border-right: 1px solid $white;
                }
              }
            }
            .tile-meta {
              display: block;
              height: 100%;
              vertical-align: middle;
              .wrapper {
                white-space: normal;
                text-align: center;
                vertical-align: middle;
                display: table;
                margin: auto;
                .hover-title {
                  position: inherit;
                  display: table;
                  margin: auto;
                  font-weight: 400;
                  font-size: 1.3rem;
                  letter-spacing: 1px;
                }

                .card-data {
                  padding: 0.8rem 0;
                  font-size: 0.7rem;
                  letter-spacing: 1px;
                  font-weight: 600;
                  margin-bottom: 1rem;
                  color: darken($orange-dark, 10%);
                  text-transform: uppercase;
                  [class^="icon-"] {
                    margin-right: 0.5rem;
                    font-size: 0.85rem;
                  }
                }
                .hover-title {
                  letter-spacing: 1px;
                  font-weight: 600;
                  font-size: 1rem;
                }
                .card-text {
                  font-size: 0.7rem;
                  letter-spacing: 0.01rem;
                }
                .news-author {
                  text-transform: uppercase;
                  border-top: 1px solid;
                  padding: 1.3rem 1rem;
                  margin-top: 2rem;
                  margin-bottom: 1.4rem;
                  font-size: 0.6rem;
                  font-weight: bold;
                  position: relative;
                  color: darken($orange-dark, 10%);
                  &:before {
                    content: '';
                    width: 1.4rem;
                    height: 3px;
                    position: absolute;
                    left: 50%;
                    margin-left: -0.8rem;
                    top: -3px;
                    background-color: darken($orange-dark, 10%);
                  }
                }
                .read-more {
                  width: 3rem;
                  display: inline-block;
                  background-color: transparent;
                  padding: 0;
                  color: $orange-dark;
                  border: none;

                  [class^="icon-"] {
                    padding: 0.7rem 0;
                    display: block;
                    transition: all 0.3s;
                    max-width: 3rem;
                    background-color: $white;
                  }
                }
              }
            }
          }

          &.onmouseover {
            .tile {
              visibility: hidden;
              opacity: 0;
            }
            .tile-hover {
              transform: scale(1);
              visibility: visible;
              opacity: 1;
            }
          }

          &.filter-on {
          }
          .card-data {

          }

          &:last-child {
            .tile {
              border-right: none;
            }
          }

          .tile {
            border-top: 1px solid $lighter-gray;
            border-bottom: 1px solid $lighter-gray;
            border-right: 1px solid $lighter-gray;
            transition: all 0.3s;
            min-height: 440px;
            height: 100%;
            padding: 0;
            .card {
              height: 100%;
              &.news-card {
                border: none;
                .card-img {
                  border-radius: 0;
                }
                .card-block {
                  .card-data {
                    padding: 0.8rem 0;
                    font-size: 0.6rem;
                    letter-spacing: 1px;
                    font-weight: 600;
                    color: lighten($dark-gray, 10%);
                    text-transform: uppercase;
                    [class^="icon-"] {
                      margin-right: 0.5rem;
                      font-size: 0.85rem;
                    }
                  }
                  .card-title {
                    letter-spacing: 1px;
                    font-weight: 600;
                    font-size: 1rem;
                  }
                }
              }
            }
          }
        }
        &.event-group {
          position: relative;
          //transform: scale(0.7);
          //visibility: hidden;
          //opacity: 0;
          transition: all 0.3s;
          //overflow: hidden;
          &.filter-on {
            //transform: scale(1);
            visibility: visible;
            overflow: visible;
            opacity: 1;
          }
          .card-data {
            padding: 0.8rem 0;
            font-size: 0.6rem;
            letter-spacing: 1px;
            font-weight: 600;
            text-transform: uppercase;
            [class^="icon-"] {
              font-size: 0.85rem;
            }
          }
          .tile {
            transition: all 0.3s;
            padding: 0;
            .card {
              &.event-card {
                border: none;
                .card-img {
                  border-radius: 0;
                }
                .card-img-overlay {
                  top: 45%;
                  color: $white;
                  right: 0;
                  background-color: rgba($black, 0.6);
                  .card-title {
                    letter-spacing: 1px;
                    color: $white;
                    font-weight: 600;
                    font-size: 1rem;
                  }

                }
              }
            }
          }
          .tile-hover {
            transform: scale(0.8);
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s;
            background-color: $orange;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            color: $white;
            overflow: hidden;
            .tile-meta {
              display: table;
              height: 100%;
              width: 100%;
              vertical-align: middle;
              .wrapper {
                width: 100%;
                text-align: center;
                vertical-align: middle;
                white-space: normal;
                display: table;
                margin: 3rem auto 0 auto;
                .created-at {
                  display: block;
                  border: 2px solid $white;
                  padding: 1.2rem;
                  font-size: 1.3rem;
                  font-weight: 600;
                  margin-left: auto;
                  margin-right: auto;
                  position: inherit;
                  text-transform: uppercase;
                  line-height: 1.1;
                  margin-bottom: 2rem;
                }
                .hover-title {
                  position: inherit;
                  display: table;
                  margin: auto;
                  font-weight: 400;
                  font-size: 1.3rem;
                  letter-spacing: 1px;
                }
                .categories {
                  position: inherit;
                  display: block;
                  .category-badge {
                    border-left: 1px solid $white;
                    padding: 0 0.3rem;
                    font-size: 0.5rem;
                    text-transform: uppercase;
                    &:last-child {
                      border-right: 1px solid $white;
                    }
                  }
                }
                .card-data {
                  color: darken($orange-dark, 20%);
                }
                .read-more {
                  width: 3rem;
                  display: inline-block;
                  background-color: transparent;
                  padding: 0;
                  color: $orange-dark;
                  border: none;

                  [class^="icon-"] {
                    padding: 0.7rem 0;
                    display: block;
                    transition: all 0.3s;
                    max-width: 3rem;
                    background-color: $white;
                  }
                }
              }
            }
          }
          &.onmouseover {
            .tile {
              visibility: hidden;
              opacity: 0;
            }
            .tile-hover {
              border-radius: 0;
              transform: scale(1);
              visibility: visible;
              opacity: 1;
            }
            a{
              color: $white;
              text-decoration: none;
            }
          }
        }

        &.colleagues-group {
          position: relative;
          transition: all 0.3s;
          .tile {
            transition: all 0.3s;
            padding: 0 0.5rem;
            .card {
              &.colleagues-card {
                border: none;
                text-align: center;
                border: 1px solid rgba(0, 0, 0, 0.125);
                .img-fluid {
                  height: 100px;
                  padding: 5% 15%;
                  @include media-breakpoint-down(sm) {
                    padding: 5% 30%;
                  }
                }

                cursor: pointer;

                button {
                  cursor: inherit;
                  margin: 1rem 0;
                }

                .card-img {
                  border-radius: 0;
                  height: 8rem;
                }
                .card-title {
                  padding: 0.5rem 1rem 0 1rem;
                  margin-bottom: 0;
                  letter-spacing: 1px;
                  color: #292b2c;
                  font-weight: 600;
                  font-size: 1rem;
                  font-weight: 600;
                  text-transform: uppercase;
                  min-height: 4rem;
                }
                .card-data {
                  white-space: normal;
                  padding-left: 1rem;
                  padding-right: 1rem;
                  padding-bottom: 0rem;
                  font-size: 0.7rem;
                  color: #777e81;
                  min-height: 4rem;
                }
              }
            }
          }
        }

        .tile {
          float: none;
          display: flex;
          background-size: cover;
          background-position: center;
          padding: 1.5rem;

          .tile-meta {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            .created-at {
              display: table;
              height: max-content;
              padding: 0.7rem;
              background-color: $orange-dark;
              color: $white;
              line-height: 1.2;
              margin-left: 2rem;
              font-size: 0.8rem;
              text-align: center;
              text-transform: uppercase;
            }
            .hover-title {
              display: none;
            }
            .categories {
              .category-badge {
                font-size: 0.5rem;
                letter-spacing: 0.03rem;
                display: inline-block;
                float: right;
                clear: right;
                padding: 0.3rem 0.5rem;
                text-transform: uppercase;
                color: $white;
                &.orange {
                  background-color: $orange;
                }
                &.real_orange {
                  background-color: $real_orange;
                }
                &.green {
                  background-color: $green;
                }
                &.turquoise {
                  background-color: $turquoise;
                }
                &.purple {
                  background-color: $purple;
                }
                &.dark-blue {
                  background-color: $dark-blue;
                }
              }
            }
          }
          .tile-content {
            width: 100%;
            vertical-align: top;
            white-space: normal;
            font-size: 0.8rem;
          }

        }
      }
    }
  }
  .tiles-control {
    top: 0;
    width: 3rem;
    z-index: 1;
    display: inline-block;
    height: 100%;
    padding: 0;
    border: none;
    color: $white;
    position: absolute;
    background-color: transparent;

    @include media-breakpoint-up(lg) {
      color: $orange;
    }

    &:not(.disabled):hover {
      [class^="icon-"] {
        background-color: rgba($orange, 0.6);
        text-shadow: 0 0 2px rgba($white, 1);
        color: $white;
        @include media-breakpoint-up(lg) {
          background-color: $orange;
        }
      }
    }

    &.tiles-control-without-hover{
      &:not(.disabled):hover {
        [class^="icon-"] {
          background-color: transparent;
          text-shadow: none;
          color: #4d4d4d;
          @include media-breakpoint-up(lg) {
            background-color: rgba($orange, 0.6);
            text-shadow: 0 0 2px rgba($white, 1);
            color: $white;
          }
        }
      }
    }

    [class^="icon-"] {
      padding: 0.7rem 0;
      display: block;
      transition: all 0.3s;
      max-width: 3rem;
      background-color: rgba($black, 0.1);
      //background-color: rgba($orange, 0.2);
      //box-shadow: 0 0 60px 1px rgba($black, 0.2);
      @include media-breakpoint-up(lg) {
        box-shadow: 0 0 20px 1px rgba($black, 0.1);
        background-color: $white;
      }
    }

    &.disabled {

      color: darken($lighter-gray, 10%);
    }

    &.left {
      left: 0;
      @include media-breakpoint-up(lg) {
        left: -1.5rem;
      }
    }

    &.right {
      right: 0;
      @include media-breakpoint-up(lg) {
        right: -1.5rem;
      }
    }
    &:focus {
      outline: none;
    }
  }
}

.social-img {
  width: 32px;
  height: 32px;
  display: inline-block;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  &.fb {
    background-image: url('../../img/FB-f-Logo__blue_50.png');
  }
  &.tw {
    background-image: url('../../img/Twitter_Social_Icon_Blue.png');
  }
  &.yt {
    background-image: url('../../img/YouTube-icon-full_color.png');
  }
  &.in {
    background-image: url('../../img/In-2C-48px-R.png');
  }
  &.ins {
    background-image: url('../../img/glyph-logo_May2016.png');
  }
}

.media {
  &.social-media {
    margin-top: 1rem;
    .media-body {
      h6 {
        font-weight: 600;
        margin: 0;
        color: inherit;
        line-height: 1.4;
        font-size: 0.7rem;
        @include media-breakpoint-up(lg) {
          font-size: 0.8rem;
        }
      }
      p {
        font-size: 0.7rem;
        line-height: 1.1;
        @include media-breakpoint-up(lg) {
          font-size: 0.85rem;
        }
      }
    }
  }
}

.large-social-links {
  li {
    a {
      display: inline-block;
      vertical-align: middle;
      color: $dark-blue;
      margin-right: 0.5rem;
      transition: all 0.2s;
      [class^="icon-"] {
        font-size: 2.5rem;
        &:before {
          margin: 0;
        }
      }
      &:hover {
        color: $orange;
      }
    }
  }
}

#teachers_tiles span, #students_tiles span, #partners_tiles span {
  background-color: #0c7697 !important;
  color: white;
  cursor: pointer;
  border-radius: 0.1rem;
  &:hover {
    opacity: 0.8;
  }
}

#teachers_tiles .tiles-control.disabled span, #students_tiles .tiles-control.disabled span, #partners_tiles .tiles-control.disabled span {
  background-color: gray !important;
  cursor: not-allowed;
  &:hover {
    opacity: 1;
  }
}

#teachers_tiles, #students_tiles, #partners_tiles {
  .card-title {
    font-size: 0.8rem;
    @include media-breakpoint-down(lg) {
      font-size: 0.55rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 0.8rem;
    }
  }
}

.colleagues-list-view-big-device {
  @include media-breakpoint-up(sm) {
    display: block;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.colleagues-title-view-small-device {
  @include media-breakpoint-up(sm) {
    display: none;
    .tiles-control {
      display: none;
    }
  }
  @include media-breakpoint-down(sm) {
    display: block;
    .tiles-control {
      display: none;
    }
  }
}