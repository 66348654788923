.form-block {
  @include media-breakpoint-up(lg) {
    padding: 0;
  }
  .form-group {
    @include media-breakpoint-up(lg) {
      border-bottom: 1px solid $lighter-gray;
      margin-bottom: 2rem;
      padding-bottom: 2rem;
    }
    &:last-child {
      border-bottom: none;
    }
    .col-form-label {
      font-size: 0.6rem;
      text-transform: uppercase;
      color: darken($lighter-gray, 15%);
      text-shadow: 0 0 1px rgba($black, 0.2);
      @include media-breakpoint-up(lg) {
        font-size: 0.75rem;
        color: darken($lighter-gray, 40%);
        letter-spacing: 0.05rem;
        font-weight: 600;
      }
    }
  }
}

.radio-group {
  display: table;
  width: 100%;
  border-spacing: 0.5rem;
  border-collapse: collapse;
  .btn-radio {
    padding: 0.6rem;
    width: 50%;
    display: table-cell;
    color: lighten($dark-gray, 30%);
    border: 1px solid $lighter-gray;
    font-size: 0.6rem;
    @include media-breakpoint-up(sm) {
      font-size: 0.8rem;
      padding: 0.8rem;
    }
    @include media-breakpoint-up(lg) {
      display: inline;
      color: $dark-gray;
      border: none;
      font-weight: 600;
      font-size: 0.8rem;
      letter-spacing: 0.05rem;
      &:before {
        content: '';
        margin: auto;
        vertical-align: middle;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
        width: 1.3em;
        height: 1.3em;
        background-image: icon(radioOff, transparent);
      }
      &.focus {
        box-shadow: none;
      }
    }
    &.active {
      border-color: $orange;
      color: $white;
      background-color: $orange;
      @include media-breakpoint-up(lg) {
        background-color: transparent;
        color: inherit;
        &:before {
          background-image: icon(radioOn, transparent);
        }
      }
    }
  }
}

.checkbox-group {
  display: table;
  width: 100%;
  .btn-checkbox {
    padding: 0.6rem;
    width: 50%;
    white-space: normal;
    display: table-cell;
    color: lighten($dark-gray, 30%);
    border: 1px solid $lighter-gray;
    border-left: none;
    font-size: 0.6rem;
    outline: none;
    box-shadow: none;
    &:first-child {
      border-left: 1px solid $lighter-gray;
      @include media-breakpoint-up(lg) {
        border-left: none;
      }
    }
    &:focus {
      outline: none;
    }
    @include media-breakpoint-up(sm) {
      font-size: 0.8rem;
      padding: 0.8rem;
    }
    @include media-breakpoint-up(lg) {
      display: inline;
      color: $dark-gray;
      border: none;
      font-weight: 600;
      font-size: 0.8rem;
      letter-spacing: 0.05rem;
      &:before {
        content: '';
        margin: auto;
        vertical-align: middle;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
        position: relative;
        width: 1.3em;
        height: 1.3em;
        background-image: icon(checkOff, transparent);
      }
    }
    &.active {
      box-shadow: none;
      border-color: $orange;
      color: $white;
      background-color: $orange;
      @include media-breakpoint-up(lg) {
        background-color: transparent;
        color: inherit;
        &:before {
          background-image: icon(checkOn, transparent);
        }
      }
    }
  }
}

.colorized-category-filter {
  margin: auto;
  width: 100%;
  max-width: 600px;
  .btn {
    &.btn-checkbox {
      font-size: 0.6rem;
      border-radius: 0;
      font-weight: 500;
      border-top: 1px solid $lighter-gray;
      border-bottom: 1px solid $lighter-gray;
      padding: 0.8rem 0;
      display: inline-block;
      text-align: center;
      width: 100%;
      max-width: 300px;
      color: lighten($gray-light, 20%);
      border-right: 1px solid lighten($gray-light, 45%);
      @include media-breakpoint-up(sm) {
        border-right: none;
        text-transform: uppercase;
        font-size: 0.7rem;
        padding: 0.8rem 0;
      }
      &:first-child {
        border-left: 1px solid $lighter-gray;
      }
      &:last-child {
        border-right: 1px solid $lighter-gray;
      }
      &.active {
        color: $white;
      }
      &:focus,
      &.focus {
        outline: none;
        box-shadow: none;
      }
      &.btn-type-0 {
        &.active {
          border-color: $orange;
          background-color: $orange;
        }
      }
      &.btn-type-1 {
        &.active {
          border-color: $green;
          background-color: $green;
        }
      }
      &.btn-type-2 {
        &.active {
          border-color: $turquoise;
          background-color: $turquoise;
        }
      }
      &.btn-type-3 {
        &.active {
          border-color: $purple;
          background-color: $purple;
        }
      }
      &.btn-type-4 {
        &.active {
          border-color: $dark-turquoise;
          background-color: $dark-turquoise;
        }
      }
    }
  }
}

.form-select {
  display: block;
  width: 100%;
  padding: .65rem .75rem;
  font-size: 0.8rem;
  line-height: 1.25;
  color: #464a4c;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  font-weight: 600;
  position: relative;
  cursor: default;
  transition: all 0.3s;
  outline: none;
  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '\e81d';
    color: $orange;
    float: right;
    vertical-align: middle;
    line-height: 1.2;
    font-family: 'metu';
  }
  .select-error {
    display: none;
  }
  &.has-error {
    select:valid + .select-error {
      color: $white;
      background-color: $red;
      display: block;
      padding: 1rem;
      margin-left: -0.8rem;
      margin-right: -0.8rem;
      margin-top: -0.8rem;
      margin-bottom: 0.8rem;
    }
  }
  .option-list {
    max-height: 60vh;
    overflow-y: auto;
    display: none;
    left: 0;
    top: 100%;
    z-index: 1000;
    position: absolute;
    background-color: $white;
    box-shadow: 0 3px 5px 1px rgba($black, 0.1);
    width: 100%;
    .option {
      color: $dark-gray;
      text-decoration: none;
      padding: 0.75em 1em;
      width: 100%;
      display: block;
      border-bottom: 1px solid rgba($black, 0.05);
      transition: all 0.3s;
      &:hover {
        background-color: darken($white, 5%);
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
  &.active {
    background-color: lighten($lighter-gray, 8%);
    .option-list {
      display: block;
    }
  }
  select {
    display: none;
  }
}

.degree-program-search-section {
  .degree-program-search {
    width: 100%;
    box-shadow: 0 0 40px 1px rgba($black, 0.1);
    background-color: $black;
    color: darken($orange-dark, 10%);
    padding: 1.5rem;
    @include media-breakpoint-up(md) {
      padding: inherit;
      margin-left: 0;
      margin-right: 0;
    }
    .btn-search {
      margin-top: 1rem;
      color: $white;
      text-transform: uppercase;
      font-weight: 600;
      padding: 0.8rem 0.5rem;
      font-size: 0.7rem;
      letter-spacing: 1px;
      background-color: transparent;
      border: 2px solid $white;
      display: block;
      width: 100%;
      &:after {
        content: '\e81f';
        font-family: 'metu';
      }
      &:hover {
        background-color: rgba($white, .2);
      }
      @include media-breakpoint-up(md) {
        margin-top: auto;
        padding: 0.65rem 0.5rem;
        border-color: transparent;
        width: auto;
      }
    }
    .search-input-col {
      width: 100%;
      clear: both;
      display: table;
      margin-bottom: 0.5em;
      @include media-breakpoint-up(md) {
        clear: none;
        display: flex;
        padding: 0 0.5rem;
      }
      .input-label {
        font-size: 0.5rem;
        color: $white;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        display: table-cell;
        width: 35%;
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
      .form-control {
        display: table-cell;
        clear: none;
        width: 100%;
      }
      .language-switcher-radio {
        width: 100%;
        margin-top: 1rem;
        .btn-checkbox {
          color: $white;
          border: 2px solid;
          font-size: 0.6rem;
          letter-spacing: 1px;
          &:first-child {
            margin-right: 0.5rem;
          }
          &:last-child {
            margin-left: 0.5rem;
          }
          &.active {
            box-shadow: none;
            outline: none;
            background-color: darken($purple, 10%);
            border-color: transparent;
          }
        }
      }
    }
    .search-row {
      @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-around;
      }
    }
  }
  &.black-mod {
    .degree-program-search {
      background-color: $black;
      @include media-breakpoint-up(lg) {

      }
    }
  }
  &.pull-up {
    @include media-breakpoint-up(lg) {
      top: -6em;
      margin-bottom: -6em;
      position: relative;
    }
  }
}

.training-level-dropdown-placeholder {
  @include media-breakpoint-up(lg) {
    min-height: 70px;
  }
}

.training-level-search-section {
  font-size: 1rem;
  padding-top: 1rem;
  //background-color: #f7af0e;
  text-transform: uppercase;
  text-align: center;
  .training-level-search {
    background-color: transparent;
    padding: 0;
    position: relative;
    margin-bottom: 2rem;
    @include media-breakpoint-up(xl) {

    }
    .search-input-col {
      padding: 0.3em;
      border: 2px dashed rgba($orange, 0.7);
      width: 100%;
      clear: both;
      display: table;
      .select_container {
        position: relative;
        width: 100%;
        cursor: pointer;
        @include media-breakpoint-up(md) {
          font-size: 1rem;
        }
        font-size: 0.6875rem;
        font-weight: 600;
        letter-spacing: 1px;
        .select_header {
          color: $orange;
          display: block;
          padding: 0.7em 1em;
          background-color: $white;
          border: 3px solid $orange;
          transition: all 0.3s;
          &:after {
            content: '\e81d';
            float: right;
            font-family: 'metu';
          }
          &:hover {
            background-color: rgba($black, 0.1);
          }
        }
        .select_items_main_container {
          display: none;
          position: absolute;
          background-color: $white;
          box-shadow: 0 0 10px 1px rgba($black, 0.2);
          z-index: 5;
          width: 100%;
          .select_items_sub_container {
            display: block;
            border-bottom: 1px solid rgba($black, 0.05);
            .select_item {
              color: $dark-gray;
              text-decoration: none;
              padding: 0.75em 1em;
              width: 100%;
              display: block;
              transition: all 0.3s;
            }
            .select_item:hover {
              background-color: darken($white, 5%);
            }
          }

        }
        .select_items_main_container.open {
          display: block;
        }
      }

    }
    .search-row {
      width: 100%;
      margin: 0 auto;
      @include media-breakpoint-up(sm) {

      }
      @include media-breakpoint-up(md) {

        display: flex;
        justify-content: space-around;
      }
      @include media-breakpoint-up(lg) {

      }
    }
    .option-list {
      width: 100%;
    }
  }
  &.black-mod {
    .degree-program-search {
      background-color: $black;
      @include media-breakpoint-up(lg) {

      }
    }
  }
  &.pull-up {
    @include media-breakpoint-up(lg) {
      top: -6em;
      margin-bottom: -6em;
      position: relative;
    }
  }
}

.news-search-section {
  .news-search {
    box-shadow: 0 0 40px 1px rgba($black, 0.1);
    background-color: $orange;
    color: darken($orange-dark, 10%);
    padding: 1.5rem;
    @include media-breakpoint-up(md) {
      padding: inherit;
      margin-left: 0;
      margin-right: 0;
    }
    .btn-search {
      margin-top: 1rem;
      color: $white;
      text-transform: uppercase;
      font-weight: 600;
      padding: 0.8rem 0.5rem;
      font-size: 0.7rem;
      letter-spacing: 1px;
      background-color: transparent;
      border: 2px solid $white;
      display: block;
      width: 100%;
      &:after {
        content: '\e81f';
        font-family: 'metu';
      }
      @include media-breakpoint-up(md) {
        margin-top: auto;
        border-color: transparent;
        width: auto;
      }
    }
    .search-input-col {
      width: 100%;
      clear: both;
      display: table;
      margin-bottom: 0.5em;
      @include media-breakpoint-up(md) {
        clear: none;
        display: flex;
        padding: 0.5rem;
      }
      .input-label {
        font-size: 0.5rem;
        color: $white;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        display: table-cell;
        width: 35%;
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
      .form-select {
        border: none;
      }
      .form-control {
        display: table-cell;
        clear: none;
        width: 100%;
      }
      .language-switcher-radio {
        width: 100%;
        margin-top: 1rem;
        .btn-checkbox {
          color: $white;
          border: 2px solid;
          font-size: 0.6rem;
          letter-spacing: 1px;
          &:first-child {
            margin-right: 0.5rem;
          }
          &:last-child {
            margin-left: 0.5rem;
          }
          &.focus,
          &.active {
            box-shadow: none;
            outline: none;
            background-color: darken($purple, 10%);
            border-color: transparent;
          }
        }
      }
    }
    .search-row {
      @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-around;
      }
    }
  }
  &.black-mod {
    .degree-program-search {
      background-color: $black;
      @include media-breakpoint-up(lg) {

      }
    }
  }
  &.pull-up {
    @include media-breakpoint-up(lg) {
      top: -6em;
      margin-bottom: -6em;
      position: relative;
    }
  }
}

.file-upload-input {
  .custom-file {
    width: 100%;
    .custom-file-control {
      &:before {
        content: "...";
        transition: all 0.3s;
        background-color: $orange;
        color: $white;
        border-color: $orange;
      }
      &:after {
        content: attr(data-filename);
      }
    }
    &:hover {
      .custom-file-control {
        &:before {
          background-color: darken($orange, 5%);
          border-color: darken($orange, 5%);
        }
      }
    }
  }
}

.customFormLabel {
  color: #979fa4;
  text-transform: uppercase;
  text-align: center;
}

.customFormControl {
  .form-group {
    border-bottom: 0 !important;
    .colorized-category-filter {
      max-width: none;
      margin: auto;
      @include media-breakpoint-up(lg) {
        margin: 0;
      }
      .btn-checkbox {
        white-space: inherit;
        border: 1px solid #e5e4e4;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 1;
      }
    }
    &.customFormCheckBox {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
    .btn-radio {
      cursor: pointer;
    }
    #lib_nap {
      background-color: $white;
    }
  }
}

.loader2 {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #f7af0e; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  position: relative;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.errorNotif{
  box-shadow: 0 0 1px red!important;
  border: 1px solid red!important;
  border-radius: 0!important;
}

.errorNotifParent{
  color: red!important;
}

.checkbox {
  input[type='checkbox'] {
    &.errorNotif {
      box-shadow: 0px 0px 1px 1px red!important;
      border: 2px solid red !important;
      color: red !important;
      background: red!important;
    }
  }
}