.front-page-slider {

  .content-wrapper {
    display: none;
    position: absolute;
    align-items: flex-start;
    right: 0;
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      min-height: 50vh;
    }
    @include media-breakpoint-up(lg) {
      min-height: 60vh;
    }
    @include media-breakpoint-up(xl) {
      min-height: 70vh;
    }

    .content-box {
      @include media-breakpoint-up(md) {
        max-width: 200px;
      }
      @include media-breakpoint-up(lg) {
        max-width: 250px;
      }
      @include media-breakpoint-up(xl) {
        max-width: 350px;
      }
    }
  }
  .carousel-item {
    cursor: pointer;
    .absolute-wrapper {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      @include media-breakpoint-down(md) {
        background-color: rgba(0, 0, 0, 0.4);
      }
      position: absolute;
      .absolute-wrapper-container {
        height: 100%;
        padding: 0;
      }

    }
  }
  .carousel-card {
    background-color: transparent;
    border: none;
    color: $white;
    max-width: 40vw;
    @include media-breakpoint-up(xl) {
      background-color: $white;
      margin-top: inherit;
      border: none;
      color: inherit;
    }
    &:before {
      @include media-breakpoint-up(xl) {
        content: '';
        width: 5rem;
        height: 3px;
        background-color: $orange;
        margin: 0 2.25rem;
      }
    }
    .card-block {
      padding: 1rem 0;
      @include media-breakpoint-up(xl) {
        padding: 2.25rem;
      }
      .card-title, h1 {
        color: $white;
        text-transform: uppercase;
        font-size: 0.7rem;
        font-weight: normal;
        letter-spacing: 2px;
        @include media-breakpoint-up(xl) {
          letter-spacing: inherit;
          font-weight: 600;
          font-size: inherit;
          color: inherit;
        }
      }
      .card-subtitle, h2, h3, h4, h5, h6 {
        color: $white;
        font-weight: normal;
        @include media-breakpoint-up(xl) {
          color: inherit;
          font-weight: 600;
          font-size: 1.2rem;
        }
        &:before {
          content: '';
          display: block;
          height: 2px;
          width: 1rem;
          background-color: $white;
          margin-bottom: 0.5rem;
          @include media-breakpoint-up(xl) {
            display: inline;
            height: inherit;
            width: inherit;
            background-color: transparent;
            margin-bottom: inherit;
            content: '- ';
          }
        }
      }
      .card-text {
        display: none;
        @include media-breakpoint-up(xl) {
          display: inherit;
          margin-top: 1.25rem;
          color: darken($lighter-gray, 50%);
        }
      }
      .card-link {
        font-size: 0.6rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding: 0.7rem 1rem;
        color: darken($orange, 5%);
        background-color: $white;
        @include media-breakpoint-up(md) {
          margin-top: 0.7rem;
        }
        @include media-breakpoint-up(xl) {
          background-color: transparent;
          font-size: 0.75rem;
          font-weight: 600;
        }
        &:after {
          content: '\e81f';
          display: inline;
          vertical-align: middle;
          font-family: 'metu';
          font-size: 0.5rem;
          margin-left: 0.5rem;
          @include media-breakpoint-up(xl) {
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  .carousel-control-prev, .carousel-control-next {
    position: absolute;
    z-index: 100;
    width: 10%;
    background-color: transparent;
    color: #f7af0e;

    span:before {
      font-weight: bold;
      font-size: 1.5rem;
    }
  }

  .carousel-control-prev {
    @include media-breakpoint-up(sm) {
      display: flex;
      margin: 0 0 0 1rem;
    }
    @include media-breakpoint-down(sm) {
      display: none;
      margin: 0;
    }
  }
  .carousel-control-next {
    @include media-breakpoint-up(sm) {
      display: flex;
      margin: 0 1rem 0 0;
    }
    @include media-breakpoint-down(sm) {
      display: none;
      margin: 0;
    }
  }

  .carousel-control-prev:hover, .carousel-control-next:hover {
    background-color: transparent;
    color: #f7af0e;
  }

  .carousel-control-prev:focus, .carousel-control-next:focus {
    background-color: transparent;
    outline: 0;
    opacity: 1;
    text-decoration: none;
  }

  .carousel-control-prev span, .carousel-control-next span {
    position: absolute;
    top: 50%;
    padding: 0.75rem 1rem;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    background-color: white;
  }

  .carousel-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    display: flex;
    position: relative;
    width: 100%;
  }

  .content-box {
    margin: 2rem;
    position: absolute;
    display: block;

    .card-block {
      @include media-breakpoint-down(xs) {
        padding: 0rem;
        p {
          font-size: 0.65rem;
          margin-bottom: 0.35rem;
        }
        div {
          margin-top: 0rem;
          .card-link {
            font-size: 0.4rem;
            padding: 0.5rem 0.6rem;
          }
        }
      }
    }

    &.top-left {
      top: 0;
      left: 0;
    }
    &.top-center {
      position: relative;
      margin: 0 auto;
      padding-top: 5.3%;
    }
    &.top-right {
      top: 0;
      right: 0;
    }
    &.bottom-left {
      bottom: 0;
      left: 0;
    }
    &.bottom-center {
      position: relative;
      margin: 0 auto;
      align-self: flex-end;
      padding-bottom: 2.7%;
    }
    &.bottom-right {
      right: 0;
      bottom: 0;
    }
    &.middle {
      position: relative;
      margin: auto;
      align-self: center;
    }
  }

}

.sub-page-slider {
  @include clearfix();
  .carousel-inner {
    .carousel-item {
      //height: 60vh;
      position: relative;
      .image-item {
        width: 100%;
        background-size: cover;
        img {
          //height: 60vh;
          //min-height: 400px;
        }
        &.center {
          background-position: center;
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    //height: 50vh;
  }
}

.showAtMobile {
  height: 100%;
  display: block;
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.hiddenAtMobile {
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
  }
}



