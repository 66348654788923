.grid-item {
    background-color: $white;
    color: $dark-gray;
    transition: all 0.3s;
    &:hover{
        opacity: 0.8;
        .grid-item-img {
            .partner-logo{
                -webkit-filter: grayscale(0);
                filter: grayscale(0);
                opacity: 1;
            }
        }
    }
    .grid-item-img {
        display: block;
        @include clearfix();
        .partner-logo{
            transition: all 0.3s;
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            opacity: 0.8;
        }
    }
    .grid-item-icon {
        &.orange{
            background-color: darken($orange, 5%);
        }
        @include clearfix();
    }
    .grid-item-footer {
        min-height: 1px;
        text-align: center;
        display: table;
        width: 100%;
        .footer-label {
            display: table-cell;
            font-weight: bold;
            text-transform: uppercase;
            vertical-align: middle;
            height: 3rem;
            padding-left: 0.5em;
            padding-right: 0.5em;
            letter-spacing: 0.05rem;
            font-size: 0.6rem;
            @include media-breakpoint-up(md) {
                height: 4rem; 
                letter-spacing: 0.02rem;
                font-size: 0.9rem;
            }
        }
    }
    &.with-border {
        .footer-label {
            border-left: 1px solid $lighter-gray;
            border-right: 1px solid $lighter-gray;
            border-bottom: 1px solid $lighter-gray;
        }
    }
    &:hover {
        text-decoration: none;
    }
}

.hide-labels{
    .grid-item{
        .grid-item-footer{
            display: none;
        }
    }
}