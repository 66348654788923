.content-stepper {
  color: $white;
  .row {
    min-height: 15rem;

    .content-stepper-heading {
      background-color: rgba($black, 0.1);
      color: $white;
      font-weight: 400;
      text-align: center;
      @include media-breakpoint-up(lg) {
        background-color: transparent;
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        height: 100%;
      }
      .content-stepper-controls {
        position: absolute;
        left: 1rem;
        right: 1rem;
        margin-top: -0.5rem;
        top: 50%;
        @include media-breakpoint-up(lg) {
          left: 50%;
          right: inherit;
          margin-top: inherit;
          margin-left: -1rem;
          top: 1rem;
          bottom: 1rem;
        }
        .control {
          vertical-align: middle;
          border: none;
          color: $white;
          background-color: transparent;
          font-size: 1rem;
          @include media-breakpoint-up(lg) {
            font-size: 2rem;
          }
          &.next {
            float: right;
            @include media-breakpoint-up(lg) {
              float: none;
              position: absolute;
              bottom: 0;
              left: 0;
            }
            .icon-chevron-right {
              @include media-breakpoint-up(lg) {
                &:before {
                  content: '\e806';
                }
              }
            }
          }
          &.prev {
            float: left;
            .icon-chevron-left {
              @include media-breakpoint-up(lg) {
                &:before {
                  content: '\e809';
                }
              }
            }
          }
        }
      }
      .heading-content {
        text-align: center;
        width: 100%;
        @include media-breakpoint-up(lg) {
          display: block;
          align-self: center;
        }
        .step-counter {
          font-size: 0.6rem;
          text-transform: uppercase;
          &:after {
            content: '';
            display: table;
            height: 2px;
            width: 2rem;
            margin: 0.5rem auto;
            background-color: rgba($white, 0.5);
          }
        }
        .step-title {
          font-weight: 600;
          color: $white;
        }
      }
    }
    .content-stepper-content {
      font-size: 0.7rem;
      @include media-breakpoint-up(lg) {
        font-size: 0.8rem;
      }
    }
  }
}