/*
.navbar {
  padding: 0;

  .bg-white {
    background-color: $white;
  }
  .navbar-brand {
    margin: 0;
    padding: 1rem 2rem;
    background-color: $brand-primary;
  }
  .bottom-navbar {
    border-top: 1px solid $lighter-gray;
    .nav-item {
      padding: 1.8rem 1.5rem;
      color: $black;
      font-size: 1.05rem;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .top-navbar {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 0.02rem;
    .nav-item {
      padding: 0.8rem 1.5rem;
      color: $dark-gray;
    }
    .dropdown {
      &.nav-item {
        padding-left: 0;
        padding-right: 0;
      }
      &.show {
        background-color: $dark-blue;
        .dropdown-toggle {
          color: $white;

        }
      }
      .dropdown-toggle {
        transition: all 0.3s;
        text-decoration: none;
        &:before {
          content: '\e807';
          font-family: "metu";
          font-weight: bold;
          margin-right: 0.5rem;
        }
        &:after {
          display: none;
        }
      }
      .dropdown-menu {
        right: 0;
        margin: 0;
        border: none;
        padding-top: 0;
        padding-bottom: 1rem;
        background-color: $dark-blue;
        &:before {
          content: '';
          height: 1px;
          display: block;
          margin: 0 1.5rem 1rem 1.5rem;
          background-color: rgba($white, 0.2);
        }
        .dropdown-item {
          font-size: 0.8rem;
          font-weight: 700;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          white-space: inherit;
          text-transform: none;
          color: $white;
          transition: all 0.3s;
          &:hover {
            background-color: rgba($white, 0.1);
          }
        }
      }
    }
  }
}
*/


