// Override bootstrap variables
@import 'common/custom-variables';
@import 'common/bs-variables';
@import 'common/mixins';

@import 'components/icons';

// @import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,800';

// Bootsrap 4
// @import '../../../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../../../vendor/twbs/bootstrap/scss/bootstrap.scss';

@import 'components/svg-icons';

// Import all SASS file from sub directories
@import 'common/global';
// @import 'components/*';
@import 'components/buttons';
@import 'components/modals';
@import 'components/fancybox';
@import 'components/forms';
@import 'components/tiles';
@import 'components/gallery';
@import 'components/list-items';
@import 'components/career-tabs';
@import 'components/sidebar';
@import 'components/person-group';
@import 'components/step-guide';
@import 'components/library-search';
@import 'components/grid-menu';
@import 'components/highlighted-block';
@import 'components/content-stepper';
@import 'components/singleworker';
@import 'components/phonebook';
@import 'components/loader';
// @import 'layout/*';
@import 'layout/carousel';
@import 'layout/footer';
@import 'layout/header';
@import 'layout/navbar';
//@import 'pages/*';
@import 'pages/front-page';
