.highlighted-block {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    @include media-breakpoint-up(md) {
        margin-left: 0;
        margin-right: 0;
    }
    .block-inner {
        padding: 1rem 1.5rem;
    }
    .block-left {
        letter-spacing: 1px;
        border-bottom: 3px solid;
        width: 100%;
        align-self: center;
        vertical-align: middle;
        display: table-cell;
        .block-inner {
            @include media-breakpoint-up(md) {
                padding: 3rem;
            }
        }
        @include media-breakpoint-up(md) {
            letter-spacing: 1px;
            border-bottom: none;
            @include make-col(3);
        }
        .block-title {
            margin: 0;
            font-size: 1.5rem;
            text-transform: uppercase;
            display: inline-block;
            margin: auto;
            color: inherit;
            @include media-breakpoint-up(md) {
                font-size: 1.25rem;
            }
            @include media-breakpoint-up(lg) {
                font-size: 2.25rem;
            }
            @include media-breakpoint-up(xl) {
                font-size: 3rem;
            }
        }
    }
    .block-right {
        width: 100%;
        @include media-breakpoint-up(md) {
            border-left: 3px solid;
            @include make-col(9);
        }
        .block-inner {
            @include media-breakpoint-up(md) {
                padding: 3rem;
            }
        }
        p {
            font-size: 0.7rem;
            letter-spacing: 0.05rem;
            &:last-child {
                margin-bottom: 0;
            }
            @include media-breakpoint-up(md) {
                font-size: 1.2rem;
            }
        }
    }
}