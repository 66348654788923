body {
}

.col-xl-2_4 {
  @include media-breakpoint-up(xl) {
    width: 20% !important;
    max-width: 20% !important;
  }
}

.section-blue {
  background: $turquoise;
}

.container-fixwidth-lg {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: ($grid-gutter-width-base / 2);
  padding-left: ($grid-gutter-width-base / 2);

  @include media-breakpoint-up(lg) {
    max-width: 1200px;
    @include make-container();
  }
}

.icon-title {
  display: table;
  margin: 0 auto 1rem auto;
  text-transform: uppercase;
  padding-top: 0.45rem;
  text-align: center;
  letter-spacing: 2px;
  position: relative;
  z-index: 0;
  font-size: 1rem;
  @include media-breakpoint-up(md) {
    letter-spacing: 4px;
    padding-top: 0.7rem;
    margin: 0 auto 2rem auto;
    font-size: 1.2rem;
  }
}

.section-title {
  display: table;
  margin: 0 auto 1rem auto;
  text-transform: uppercase;
  padding-top: 0.45rem;
  text-align: center;
  letter-spacing: 2px;
  font-weight: 700;
  position: relative;
  padding-bottom: 6px;
  z-index: 0;
  font-size: 1rem;
  @include media-breakpoint-up(md) {
    letter-spacing: 4px;
    padding-top: 0.7rem;
    margin: 0 auto 1.5rem auto;
    font-size: 1.2rem;
  }
  @include media-breakpoint-up(lg) {
    max-width: 40vw;
  }
  &:before {
    z-index: -1;
    display: block;
    width: 30px;
    border: 3px solid $lighter-gray;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -15px;
    @include media-breakpoint-up(md) {
      bottom: inherit;
      width: 44px;
      height: 44px;
      margin-left: -22px;
    }
  }
  &.inverse {
    &:before {
      border-color: rgba($white, 0.5);
    }
    &:after {
      border-color: $white;
    }
    &.orange {
      &:after {
        border-color: $orange;
      }
    }
  }
  &:after {
    border-bottom: 3px solid $orange;
    position: absolute;
    top: 100%;
    margin-top: -3px;
    left: 50%;
    z-index: -1;
    content: '';
    display: block;
    width: 12px;
    margin-left: -6px;
    @include media-breakpoint-up(md) {
      width: 16px;
      margin-top: 0;
      top: 0;
      height: 44px;
      margin-left: -8px;
    }
  }
  small {
    display: table;
    margin: 0 auto;
  }
}

.sub-title {
  display: block;
  //text-align: center;
  letter-spacing: 0.1rem;
  font-weight: 900;
  font-size: 0.8rem;
  color: lighten($gray, 20%);
  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }
}

.search-section-title{
  &:before{
    content: normal;
  }
  &:after{
    content: normal;
  }
}

$colors: ("red": $red, "orange": $orange, "orange-dark": $orange-dark, "lighter-gray": $lighter-gray, "dark-gray": $dark-gray, "white": $white, "black": $black, "brand-primary": $brand-primary, "green": $green, "dark-green": $dark-green, "turquoise": $turquoise, "purple": $purple, "dark-blue": $dark-blue, "darker-blue": $darker-blue);
@each $color in $colors {
  $colorName: nth($color, 1);
  $colorValue: nth($color, 2);
  .bg-#{$colorName} {
    background-color: $colorValue !important;
  }
  .text-#{$colorName} {
    color: $colorValue !important;
  }
}

.badge-group {
  border-collapse: separate;
  display: flex;
  .outline-badge {
    margin: 5px;
    border: 1px solid;
    border-left: 4px solid;
    padding: 0.3rem 0.5rem;
    vertical-align: middle;
    font-size: 0.5rem;
    display: inline-block;
    @include media-breakpoint-up(lg) {
      padding: 0.6rem 1.5rem;
      font-size: 0.8rem;
      height: auto;
    }
  }
}

.text-columns-content {
  &:last-child {
    border-bottom: none;
  }
  b {
    color: lighten($gray, 20%);
    font-size: 0.7rem;
    @include media-breakpoint-up(lg) {
      font-size: 0.95rem;
    }
  }
}

.text-columns-content, .text-content {
  font-size: 0.8rem;

  ul {
    list-style-type: none;
    padding-left: 1rem;
    li {
      padding-left: 1em;
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        width: 0.5em;
        height: 0.5em;
        position: absolute;
        left: 0;
        top: 0.5em;
        background-color: lighten($black, 90%);
        align-self: center;
      }
    }
  }

  img {
    max-width: 100%;
    margin: 3rem;
  }

  h1:not(.media-heading) {
    font-size: 1.5rem;
  }
  h2:not(.media-heading) {
    font-size: 1.2rem;
  }
  h3:not(.media-heading) {
    font-size: 1rem;
  }
  h4:not(.media-heading) {
    font-size: 1rem;
  }
  h5:not(.media-heading) {
    font-size: 0.75rem;
  }
  h6:not(.media-heading) {
    font-size: 0.75rem;
  }

  h1, h2, h3, h4, h5, h6 {
    &:not(.media-heading) {
      text-transform: uppercase;
      display: flex;
      align-items: center;
      font-weight: 600;
      max-width: 100%;
      text-align: center;

      @include media-breakpoint-up(lg) {
        text-align: unset;
        letter-spacing: 0.02rem;
        
        &:after {
          content: ''; 
          flex-grow: 1; 
          margin-left: 0.8rem; 
          border-bottom: 1px solid #e5e4e4;
        }
      }
      &:before {
        content: '';
        width: 0;
        height: 0.85em;
        margin-right: 0.5em;
        vertical-align: bottom;
        margin-bottom: 0.15em;
        border-left: 0.9em solid $orange;
        display: none;
        @include media-breakpoint-up(lg) {
          display: inline-flex;
        }
      }
    }
  }
}

.shadowed-sheet {
  background-color: $white;
  @include media-breakpoint-up(md){
    box-shadow: 0 0 50px 1px rgba($black, 0.1);
  }
}

.pull-up-lg-10 {
  @include media-breakpoint-up(lg) {
    position: relative;
    margin-top: -10vh;
  }
}

.pull-up-lg-20 {
  @include media-breakpoint-up(lg) {
    position: relative;
    margin-top: -20vh;
  }
}

.pull-up-lg-30 {
  @include media-breakpoint-up(lg) {
    position: relative;
    margin-top: -15%;
  }
}

.attract-below-lg-30 {
  @include media-breakpoint-up(lg) {
    position: relative;
    margin-bottom: -15%;
  }
}

.mw-sm-70 {
  @include media-breakpoint-up(sm) {
    width: 70%;
    display: table;
    margin: auto;
  }
}

.mw-lg-60 {
  @include media-breakpoint-up(lg) {
    width: 60%;
    display: table;
    margin: auto;
  }
}

.animated-loading-bar {
  display: inline-block;
  margin: auto;
  width: 44px;
  height: 44px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 0 3px $lighter-gray inset;
  &:after {
    content: '';
    height: 16px;
    width: 16px;
    background-color: $orange;
    display: block;
    position: absolute;
    top: -13px;
    left: -13px;
    animation-name: animatedLoadingBar;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @keyframes animatedLoadingBar {
    0% {
      top: -13px;
      left: -13px;
    }
    25% {
      top: -13px;
      left: 41px;
    }
    50% {
      top: 41px;
      left: 41px;
    }
    75% {
      top: 41px;
      left: -13px;
    }
  }
}

.category-heading {
  text-transform: uppercase;
  text-align: center;
  font-size: 0.8rem;
  padding: 1.2em;
  //@include media-breakpoint-up(lg){
  //  font-size: 0.8rem;
  //  padding: 1.2rem;
  //}
  [class^="icon-"] {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
}

.dark-faded-bg {
    background-color: rgba($black, 0.4) !important;
}

.media {
  &.green-media {
    .person-name {
      font-size: 1rem;
      font-weight: 600;
      text-transform: uppercase;
      color: darken($dark-green, 10%);
      margin-bottom: 0.3em;
    }
    .person-title {
      font-size: 0.8rem;
      color: $dark-gray;
    }
  }
}

.letter-format {
  &.green {
    h1, h2, h3, h4, h5, h6 {
      color: darken($dark-green, 10%);
    }
  }
  p {
    font-size: 0.9rem;
    margin-bottom: 2rem;
  }
  h4 {
    font-size: 1rem;
  }
  h1, h2, h3 {
    font-weight: 600;
    margin-bottom: 1rem;
  }
  h4, h5, h6 {
    text-transform: uppercase;
    font-weight: 600;
  }
}

.bg-vert-gradient {
  @include gradient-y($white, #eee, 60%, 100%);
}

.offset-top-3 {
  z-index: 40;
  position: relative;
  top: -3rem !important;
  margin-bottom: -5rem;
}

blockquote {
  font-size: 1.1em;
  font-weight: 400;
  font-style: italic;
  color: $gray-light;
  &:before, &:after {
    content: '"';
  }
}

.event-data-card {
  @include media-breakpoint-up(md) {
    text-align: center;
  }
  .event-data {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: block;
    @include media-breakpoint-up(md) {
      display: inline-block;
      margin: auto;
    }
    .event-data-item {
      text-align: left;
      display: block;
      padding: 1em;
      background-color: $white;
      border-top: 3px solid $orange;
      box-shadow: 0 0 10px 1px rgba($black, 0.05);
      font-size: 0.8rem;
      min-width: 10rem;
      margin-bottom: 1em;
      @include media-breakpoint-up(md) {
        display: inline-block;
        margin-bottom: inherit;
      }
      .label {
        font-weight: 600;
      }
    }
  }
}

.cover-all-xl {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  @include media-breakpoint-down(md) {
    font-size: 0.8rem;
  }
}

.setPicturesMargin img{
  margin: 0.1rem;
}