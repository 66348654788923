$orange: #f7af0e;
$real_orange: #f7af0e;
$orange-dark: #f6b100;
$lighter-gray: #e5e4e4;
$dark-gray: #4d4d4d;
$white: #ffffff;
$black: #000000;
$brand-primary: $orange;/*#ffcf27;*/
$green: #7ab100;
$dark-green: #839E29;
$turquoise: #37bed6;
$light-turquoise:#23b9de;
$purple: #971D66;
$dark-blue : #0c7697;
$darker-blue : #214b6b;
$magenta: #D61B8E;
$red: #D80043;
$dark-turquoise: #2fa199;

$icons: ( "arrow-left": "\e800", "arrow-right": "\e801", "arrow-up": "\e802", "barrier-empty": "\e803", "barrier-filled": "\e804", "chain": "\e805", "chevron-down": "\e806", "chevron-left": "\e807", "chevron-right": "\e808", "chevron-up": "\e809", "clock": "\e80a", "close": "\e80b", "down-arrow-circle": "\e80c", "envelope": "\e80d", "event": "\e80e", "facebook-circled": "\e80f", "globe": "\e810", "hamburger": "\e811", "instagram": "\e812", "linkedin-circled": "\e813", "marker": "\e814", "megaphone": "\e815", "metu": "\e816", "pantheon": "\e817", "pencil": "\e818", "phone": "\e819", "play-circled": "\e81a", "search": "\e81b", "shooting": "\e81c", "small-chevron-down": "\e81d", "small-chevron-left": "\e81e", "small-chevron-right": "\e81f", "small-chevron-up": "\e820", "twitter-circled": "\e821", "youtube-circled": "\e822", "arrow-down": "\e823")