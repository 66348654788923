body.modal-open .navbar{
  display: none;
}

.navbar {
  padding: 0;
  display: block;
  background-color: $orange-dark;
  height: 4rem;
  z-index: 1030;
  box-shadow: 0 6px 10px rgba($black, 0.1);
  //transition: all 0.3s;

  @include media-breakpoint-up (lg) {
    position: fixed;
    height: inherit;
    background-color: transparent;
  }

  @media (min-width: 1500px) {
    width: inherit;
    height: inherit;
    background-color: transparent;
    box-shadow: none;
    max-width: 1170px;
    margin: auto;
    padding-left: 0 !important;
    padding-right: 0 !important;
    position: fixed;
    left: 50%;
    margin-left: -585px;
  }

  .navbar-container-mod {
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      //margin-right: ($grid-gutter-width / -2);
      //margin-left:  ($grid-gutter-width / -2);
      box-shadow: 0 4px 10px 1px rgba($black, 0.1);
    }
  }

  .collapsible-content {
    transition: all 0.3s;
    height: 0;
    display: block;
    overflow-y: hidden;
    overflow-x: hidden;
    visibility: hidden;
    opacity: 0;
    @include media-breakpoint-up(lg) {
      background-color: $white;
      height: auto;
      display: block;
      width: calc(100% - 10rem);
      overflow-y: inherit;
      overflow-x: inherit;
      visibility: visible;
      opacity: 1;
    }
    &.search-active {
      .quick-nav {
        visibility: hidden;
        opacity: 0;
        overflow: hidden;
      }
      .top-nav {
        visibility: hidden;
        opacity: 0;
        overflow: hidden;
      }
      .xs-sm-bottom-bar {
        visibility: hidden;
        opacity: 0;
        overflow: hidden;
      }
      .menu-search-group {
        background-color: rgba($black, 0.1);
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        transition: all 0.3s;
        @include media-breakpoint-up(lg) {
          background-color: $white;
          opacity: 1;
          visibility: visible;
        }
      ;
        .input-group {
          border-bottom: 0;
        }
        .form-control {
          font-weight: 400;
        }
      }
    }
    .top-labels {
      list-style-type: none;
      margin: 0;
      padding-right: 0;
      padding-top: 0;
      padding-bottom: 0.5em;
      padding-left: 1.5rem;
      @include media-breakpoint-up(lg) {
        border-bottom: 1px solid #eceeef;
      }
      .top-label-item {
        display: inline-block;
        a {
          padding: 0.5em 2em;
          background: $light-turquoise;
          color: $white;
          line-height: 1;
          display: inline-block;
          font-size: 0.6rem;
          text-transform: uppercase;
          transition: all 0.3s;
          text-decoration: none;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    &.no-top-labels{
      .top-labels{
        display: none;
      }
      .menu-search-group{
        top: 0;
        .form-control{
          padding: 0.5rem 0;
        }
      }
    }
  }

  &.scrolled {
    .navbar-container{
      box-shadow: 0 0 10px rgba($black, 0.2);
    }
    .top-labels {
      display: none;
    }
    .top-nav {
      display: none;
    }
    .quick-nav {
      display: none;
    }
    .primary-nav {
      padding: 0;
      float: left;
      clear: inherit;
      border-top: none;
      ul {
        .primary-nav-item {
          a {
            font-size: 0.8rem;
            letter-spacing: 0.01rem;
            padding: 1rem;
          }
        }
      }
    }
    .quick-nav {
      .quick-nav-label {
        font-size: 0.8rem;
        letter-spacing: 0.01rem;
        line-height: 1.5;
        padding: 1rem;
      }
    }
    .header-bar {
      .navbar-logo {
        background-image: url('../../img/logo_landscape.svg');
        background-size: 80%;
        background-position: center;
        background-repeat: no-repeat;
        &.navbar-logo-en{
          background-image: url('../../img/logo_landscape_en.svg');
        }
        img {
          display: none;
        }
      }
    }
    .xs-sm-bottom-bar {
      border-left: none;
    }
    .menu-search-group {
      top: 0;
    }
    .breadcrumb {
      display: none;
    }
  }

  &.open {
    bottom: 0;
    height: 100%;
    .collapsible-content {
      overflow-y: auto;
      overflow-x: hidden;
      height: auto;
      visibility: visible;
      opacity: 1;
    }
    .xs-sm-bottom-bar {
      visibility: visible;
      opacity: 1;
    }
  }

  .header-bar {
    display: block;
    flex-direction: row;
    height: 4rem;
    background-color: $orange-dark;
    @include media-breakpoint-up(lg) {
      height: inherit;
    }
    .navbar-logo {
      display: flex;
      padding: 1rem;
      background-image: url('../../img/logo_landscape.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 10rem;
      height: 100%;
      margin: 0 1rem;
      &.navbar-logo-en{
        background-image: url('../../img/logo_landscape_en.svg');
      }
      @include media-breakpoint-up(lg) {
        margin: inherit;
        background-image: url('../../img/logo_portrait.svg');
        background-position: center;
        text-align: center;
        background-size: 80% 80%;
        display: block;
        &.navbar-logo-en{
          background-image: url('../../img/logo_portrait_en.svg');
        }
      }
    }

    .toggle-button {
      vertical-align: middle;
      text-align: center;
      background-color: transparent;
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: 2rem;
      line-height: 1;
      border: none;
      display: inline-block;
      height: 2rem;
      @include media-breakpoint-up(lg) {
        display: none;
      }
      &:focus {
        outline: none;
      }
      .toggle-icon {
        position: relative;
        animation-name: rotateFadeIcon;
        animation-duration: 0.3s;
        display: inline-block;
        margin: auto;
        width: 1em;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        font-size: 1rem;
        color: $white;
        font-family: 'metu';
        &:before {
          top: 0;
          left: 0;
          transform: rotate(-180deg);
          position: absolute;
          visibility: hidden;
          opacity: 0;
          transition: all 0.3s;
          content: '\e80b';
        }
        &:after {
          top: 0;
          left: 0;
          transform: rotate(0);
          position: absolute;
          transition: all 0.3s;
          visibility: visible;
          opacity: 1;
          content: '\e811';
        }
      }
      &.active {
        .toggle-icon {
          &:before {
            transform: rotate(0);
            visibility: visible;
            opacity: 1;
          }
          &:after {
            transform: rotate(180deg);
            visibility: hidden;
            opacity: 0;
          }
        }
      }
    }
  }

  .top-nav {
    overflow-y: hidden;
    overflow-x: auto;
    visibility: visible;
    opacity: 1;
    height: auto;
    max-height: 600px;
    white-space: nowrap;
    transition: all 0.4s ease-in-out;
    background-color: darken($orange-dark, 5%);
    @include media-breakpoint-up(lg) {
      white-space: normal;
      float: right;
      background-color: transparent;
      margin-right: 1rem;
    }
    ul {
      list-style-type: none;
      display: inline-block;
      margin-bottom: 0;
      padding: 0;
      white-space: nowrap;
      .top-nav-item {
        display: inline;
        color: $gray-light;
        @include media-breakpoint-up(lg) {
          border-right: 1px solid;
          &:last-child {
            border-right: none;
          }
        }
        a {
          display: inline-block;
          padding: 1rem;
          color: darken($orange-dark, 15%);
          @include media-breakpoint-up(lg) {
            font-size: 0.75rem;
            //padding: 0.8rem 0.5rem;
            color: $gray-light;
          }
        }
      }
    }
    .top-labels {
      list-style-type: none;
      display: inline-block;
      padding-left: 0;
      .top-label-item {
        display: inline;
        color: $gray-light;
        a {
          display: inline-block;
          padding: 1rem;
          text-transform: none;
          font-size: inherit;
          background-color: transparent;
          color: darken($orange-dark, 15%);
          &:hover {
            opacity: 0.8;
          }
        }
        [class*="bg-"] {
          background-color: transparent !important;
        }
      }
    }
  }

  .primary-nav {
    margin: 0 0 1em 0;
    padding: 0 1rem;
    overflow: auto;
    height: auto;
    max-height: 600px;
    transition: all 0.4s ease-in-out;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      display: block;
      height: inherit;
      clear: both;
      overflow: inherit;
      padding: 0 0.5rem;
      border-top: 1px solid $gray-lighter;
      @include clearfix();
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      @include media-breakpoint-up(lg) {
        display: block;
      }
      .primary-nav-item {
        display: block;
        transition: all 0.3s;
        height: auto;
        @include media-breakpoint-up(lg) {
          float: left;
          position: relative;
          &:hover .sub-menu {
            display: block;
            z-index: 10000;
          }
          &:focus .sub-menu {
            display: none;
          }
        }

        &:focus .sub-menu {
          display: block;
          z-index: 10000;
        }

        a {
          display: block;
          color: $white;
          font-size: 1rem;
          text-transform: uppercase;
          font-weight: 600;
          letter-spacing: 1px;
          text-decoration: none;
          padding: 0.5rem;
          transition: all 0.3s;
          @include media-breakpoint-up(lg) {
            color: $black;
            padding: 1.5rem 1rem;
            &:hover {
              color: darken($orange, 5%);
            }
          }
        }
        &.has-submenu {
          > a {
            &:after {
              content: '\e80c';
              font-weight: normal;
              float: right;
              font-family: 'metu';
              @include media-breakpoint-up(lg) {
                display: none;
              }
            }
          }
          > a.openIcon:after {
            transform: rotate(-180deg);
          }
        }

        .sub-menu {
          display: none;
          padding: 0;
          &.open {
            display: block;
          }
          @include media-breakpoint-up(lg) {
            overflow: inherit;
            position: absolute;
            transition-delay: 1s;
            width: 200px;
            max-height: inherit;
            padding: 0.5rem 0;
            background-color: $orange-dark;
            box-shadow: 0 2px 10px 1px rgba($black, 0.1);
            left: 0;
            right: 0;
            &:after {
              content: '';
              width: 1rem;
              margin-left: -0.5rem;
              left: 20%;
              display: block;
              position: absolute;
              height: 0.5rem;
              border-left: 0.5rem solid transparent;
              border-right: 0.5rem solid transparent;
              border-bottom: 0.5rem solid $orange-dark;
              top: -0.5rem;
            }
          }
          .sub-menu-item {
            border-bottom: 1px solid lighten($orange-dark, 10%);
            @include media-breakpoint-up(lg) {
              border-bottom: none;
            }
            &.menu-title {
              text-transform: uppercase;
              font-weight: 600;
              border-bottom: 1px solid rgba($white, 0.1);
              &.sub-menu-item {
                .sub-menu-link:hover {
                  background-color: transparent;
                }
              }
            }
            a {
              color: $white;
              text-decoration: none;
              display: block;
              padding: 0.5rem 1rem;
              text-transform: none;
              font-weight: 400;
              font-size: 0.75rem;
              letter-spacing: 0.04rem;
              @include media-breakpoint-up(lg) {
                padding: 0.5rem 1rem;
              }
              &:hover {
                background-color: rgba($white, 0.1);
              }
            }
            &:last-child {
              border-bottom: none;
            }
          }
        }

        .my-brand svg {
          height: 1.5rem;
          @include media-breakpoint-up(md) {
            .st1 {
              fill: #000000;
            }
          }
          @include media-breakpoint-down(md) {
            .st0 {
              fill: #FFFFFF;
            }
          }
        }
      }
    }
  }

  .xs-sm-bottom-bar {
    transition: all 0.3s;
    position: absolute;
    background-color: $orange-dark;
    visibility: hidden;
    opacity: 0;
    bottom: 0;
    width: 100%;
    padding: 0.8rem;
    display: block;
    justify-content: space-between;
    box-shadow: 0 0 10px 1px rgba($black, 0.1);

    @include media-breakpoint-up(lg) {
      opacity: 1;
      display: table-cell;
      float: right;
      justify-content: flex-end;
      min-width: 2.5rem;
      width: auto;
      visibility: visible;
      position: inherit;
      bottom: inherit;
      white-space: nowrap;
      background-color: transparent;
      box-shadow: none;
      border-left: 1px solid $gray-lighter;
    }

    .lang-switch-btn {
      width: 4rem;
      display: inline-block;
      max-height: 1.8rem;
      vertical-align: middle;
      height: 2rem;
      color: $white;
      float: right;
      @include media-breakpoint-up(lg) {
        float: left;
        max-height: inherit;
        background-color: transparent;
        vertical-align: top;
        width: 1em;
        height: inherit;
        margin: 0 1.5rem 0 0.5rem;
        text-decoration: none;
        &:before {
          content: '\e810';
          font-family: 'metu';
          display: inline-block;
        }
      }
      .lang-code {
        font-size: 0.6rem;
        text-transform: uppercase;
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        margin: -0.1rem 0;
        display: inline-block;
        text-align: center;
        @include media-breakpoint-up(lg) {
          display: none;
        }
        &.active {
          color: #D88501;
          background-color: $white;
          box-shadow: 0 0 4px 1px rgba($black, 0.1);
          @include media-breakpoint-up(lg) {
            display: inline-block;
            box-shadow: none;
            color:$black;
          }
        }
      }
    }
    .barrier-btn {
      float: left;
      background-color: $white;
      border-radius: 2rem;
      width: 2rem;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      height: 2rem;
      box-shadow: 0 0 4px 1px rgba($black, 0.1);
      @include media-breakpoint-up(lg) {
        float: right;
        box-shadow: none;
        height: 1.5em;
        width: 1.5em;
        margin: 0 0.5rem;
      }
      .icon {
        line-height: 2rem;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        font-family: 'metu';
        @include media-breakpoint-up(lg) {
          line-height: 1;
        }
        &:before {
          content: '\e804';
          position: absolute;
          color: $orange;
        }
        &:after {
          content: '\e803';
          color: $black;
        }
      }
    }
    .search-btn {
      float: left;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      color: $dark-gray;
      margin-left: 0.3rem;
      position: relative;
      .icon-search {
        vertical-align: bottom;
      }
      @include media-breakpoint-up(lg) {
        transition: all 0.3s;
        margin: 0 0.3rem;
        &:after {
          content: '';
          display: block;
          position: absolute;
          background-color: transparent;
          padding: 1rem;
          top: -0.3rem;
          left: -0.5rem;
          //margin: 0 -0.5rem;
          width: 2rem;
          height: 2rem;
          border-radius: 2rem;
          transform: scale(0.5);
          transition: all 0.3s;
        }
        &:hover {
          &:after {
            transform: scale(1);
            background-color: rgba($black, 0.05);
          }
        }
      }
    }
  }

}

.menu-search-group {
  padding: 0.5rem;
  transition: all 0.3s;
  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 1.5rem;
    display: block;
    visibility: hidden;
    opacity: 0;
    right: 0;
    left: 10rem;
    background-color: $white;
    transition: all 0.3s;
  }
  .input-group {
    border-bottom: 1px solid $white;
    @include media-breakpoint-up(lg) {
      border-bottom: none;
    }
    .input-group-addon {
      border: none;
      background-color: transparent;
      color: $white;
      font-family: 'metu';
      font-size: 0.8rem;
      &:before {
        font-weight: 900;
        content: '\e81b';
      }
      @include media-breakpoint-up(lg) {
        color: $dark-gray;
      }
      &.clear-input {
        display: none;
        &:before {
          border: 2px solid $white;
          border-radius: 1rem;
          width: 1.1rem;
          height: 1.1rem;
          display: inline-block;
          line-height: 0.9rem;
          font-size: 0.5rem;
          font-weight: 900;
          content: '\e80b';
        }
      }
    }
  }
  .form-control {
    padding-left: 0;
    padding-right: 0;
    border: none;
    font-weight: 600;
    transition: all 0.3s;
    background-color: transparent;
    color: $white;
    @include placeholder($white);
    @include media-breakpoint-up(lg) {
      color: $dark-gray;
      font-weight: normal;
      padding: 1rem 0;
      @include placeholder(lighten($black, 75%));
    }
  }
  .close_search {
    display: none;
    color: #fff;
    position: absolute;
    left: 50%;
    z-index: 3;
    top: 1.4rem;
  }
}

.quick-search-wrap {
  display: none;
  margin: 0;
  padding: 0 1rem;
  overflow: auto;
  height: auto;
  max-height: 600px;
  transition: all 0.4s ease-in-out;
  .quick-search-title {
    text-transform: uppercase;
    font-size: 0.75em;
    color: rgba($white, 0.8);
    letter-spacing: 1px;
    padding-bottom: 0.5rem;
    margin: 1rem 0.5rem 0 0.5rem;
    display: block;
    border-bottom: 1px solid #ffc32a;
  }
  .quick-search {
    list-style-type: none;
    margin: 0;
    padding: 0 0.5rem;
    .quick-search-item {
      border-bottom: 1px solid #ffc32a;
      .quick-search-link {
        color: #fff;
        text-decoration: none;
        display: block;
        padding: 0.5rem 0;
        font-size: 0.9rem;
        letter-spacing: 0.04rem;
        font-weight: 400;
        transition: all 0.3s;
        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
}

.quick-nav {
  display: block;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  @include media-breakpoint-up(lg) {
    padding: 0 1.5rem;
    position: relative;
    float: left;
    display: inline-block;
    &:hover {
      color: $white;
      background-color: $dark-blue;
    }
  }
  .quick-nav-label {
    border: none;
    font-size: 1rem;
    text-transform: uppercase;
    background-color: transparent;
    font-weight: 600;
    color: $white;
    text-align: left;
    padding: 0.5rem;
    display: block;
    width: 100%;
    &:focus {
      outline: none;
    }
    @include media-breakpoint-up(lg) {
      color: inherit;
      width: inherit;
      line-height: 1;
      padding: 1rem;
      display: inline-block;
      padding-left: 0;
      border-bottom: 1px solid white;
    }

    &.open:before {
      transform: rotate(-180deg);
    }
    &:before {
      content: '\e80c'; // up: \e809
      font-family: 'metu';
      display: inline-block;
      vertical-align: middle;
      float: right;
      width: 1.1rem;
      height: 1rem;
      font-size: 1rem;
      font-weight: normal;
      @include media-breakpoint-up(lg) {
        float: none;
        font-weight: 600;
        width: 0.8rem;
        margin-right: 0.3rem;
        line-height: 0.7rem;
        height: 0.8rem;
        font-size: 0.8rem;
      }
    }
  }

  &:focus {
    .quick-nav-label + ul {
      display: block;
      z-index: 10000;
      max-height: 600px;
      @include media-breakpoint-up(lg) {
        display: none;
      }
    ;
    }
  }
  @include media-breakpoint-up(lg) {
    &:hover {
      .quick-nav-label + ul {
        display: block;
      }
    ;
    }
  ;
  }
;

  ul {
    margin: 0;
    padding: 0;
    display: none;
    max-height: 0;
    list-style-type: none;
    transition: all 0.3s;
    &.open {
      display: block;
    }
    @include media-breakpoint-up(lg) {
      max-height: inherit;
      z-index: 10000;
      background-color: $dark-blue;
      position: absolute;
      width: 100%;
      left: 0rem;
    }
    .quick-nav-item {
      display: block;
      float: left;
      width: 100%;
      border-bottom: 1px solid lighten($orange, 10%);
      &:last-child {
        border-bottom: none;
      }
      @include media-breakpoint-up(lg) {
        border-bottom: none;
        float: none;
      }
      a {
        display: block;
        padding: 0.5rem 1rem;
        color: $white;
        text-decoration: none;
        font-size: 0.75rem;
        letter-spacing: 0.04rem;
        @include media-breakpoint-up(lg) {
          padding: 0.5rem 1.5rem;
          font-size: inherit;
        }
        &:hover {
          background-color: rgba($white, 0.1);
        }
      }
    }
  }
}

.breadcrumb {
  display: none;
  @include media-breakpoint-up(lg) {
    margin-top: 10px;
    border-top: 1px solid $orange;
    border-bottom: 3px solid $orange;
    background-color: $white;
    margin: 0;
    display: block;
  }
  .breadcrumb-item {
    color: $black;
    font-size: 0.8rem;
    &.active {

    }
    &:before {
      color: $black;
    }
    a{
      color: $black;
    }
  }
}